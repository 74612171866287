import type Column from "./Column/model";

export default class Page {
  width: number = 0;
  height: number = 0;
  paddingTop: number = 0;
  paddingRight: number = 0;
  paddingBottom: number = 0;
  paddingLeft: number = 0;
  order: number = 0;
  footer: {
    documentTitle: boolean;
    pageNumber: boolean;
    applicationTitle: boolean;
  } = {
    documentTitle: true,
    pageNumber: true,
    applicationTitle: true,
  };

  readonly footerHeight: number = 40;
  readonly contentWidth: number = 0;
  readonly contentHeight: number = 0;

  private _columns: Column[] = [];
  private _columnsTotalWidth: number = 0;
  private _columnsHeight: number = 0;

  constructor(
    width: number,
    height: number,
    padding: { top: number; right: number; bottom: number; left: number },
    order?: number,
  ) {
    this.width = width;
    this.height = height;
    this.paddingTop = padding.top;
    this.paddingRight = padding.right;
    this.paddingBottom = padding.bottom + this.footerHeight;
    this.paddingLeft = padding.left;
    this.contentWidth = this.width - this.paddingLeft - this.paddingRight;
    this.contentHeight = this.height - this.paddingTop - this.paddingBottom;
    this.order = order || 0;
  }

  get columns() {
    return this._columns;
  }

  addColumn(column: Column) {
    this._columns.push(column);
    this._columnsTotalWidth += column.width;
    this._columnsHeight += column.height;
  }

  availableWidth() {
    return this.contentWidth - this._columnsTotalWidth;
  }

  availableHeight() {
    return this.contentHeight - this._columnsHeight;
  }

  itemsId(): string[] {
    return this._columns.flatMap((column) => column.itemsIds());
  }
}
