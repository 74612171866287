<script setup lang="ts">
type FetchingProps = {
  terms: string;
  status: "loading" | "error" | undefined;
};

const props = defineProps<FetchingProps>();
</script>
<template>
  <div :class="`fetching ${props.status}`">
    <div class="fetching__content">
      <p>{{ props.terms }}</p>
    </div>
    <div class="fetching__loading-bar">
      <v-progress-linear
        v-if="props.status && props.status !== 'error'"
        color="gray"
        indeterminate
        location="bottom"
        height="7rem"
      ></v-progress-linear>
    </div>
  </div>
</template>
<style scoped lang="scss">
.fetching {
  position: relative;
  place-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 0.5rem;
  overflow: hidden; // Loading bar goes out without this

  &.loading {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &.error {
    background-color: rgb(176, 0, 32);
  }

  .fetching__content {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-grow: 1;

    p {
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .fetching__loading-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
</style>
