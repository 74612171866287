import ColumnItem, { ColumnItemType } from "../model";
import type { Component } from "vue";
import BreakFlowComponent from "./BreakFlow.vue";

export default class BreakFlow extends ColumnItem {
  constructor(content: object = {}) {
    super(content);
  }

  protected _getComponent(): Component {
    return BreakFlowComponent;
  }
  protected _getType(): ColumnItemType {
    return ColumnItemType.BREAK_FLOW;
  }

  protected _getParametersFromContent(): object {
    return {};
  }

  protected _getWidth(): number {
    return 30;
  }

  protected _getContentHeight(): number {
    return 30;
  }

  protected _getPaddingTop(): number {
    return 15;
  }

  protected _getPaddingBottom(): number {
    return 15;
  }
}
