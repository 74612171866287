import { wrapEventBus } from "@/utils/eventBus";
import mitt from "mitt";
import type { ColumnItemType } from "./model";

// Event type
export type ColumnItemEvent = {
  [columnItem: string]: {
    id: string;
    type: ColumnItemType;
    content: object;
    event: "click" | "dblclick" | "drop";
    at?: "top" | "bottom";
    itemDroppedId?: string;
  };
};

// Event emitter
export const columnItemEvent = mitt<ColumnItemEvent>();

// Event listener
export const columnItemEventBus = wrapEventBus(columnItemEvent);
