import { wrapEventBus } from "@/utils/eventBus";
import mitt from "mitt";

// Event type
export type PageComponentEvent = {
  [update: string]: {
    event: "click";
    pageNumber: number;
  };
};

// Event emitter
export const pageComponentEvent = mitt<PageComponentEvent>();

// Event listener
export const pageComponentEventBus = wrapEventBus(pageComponentEvent);
