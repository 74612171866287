<script setup lang="ts">
import { computed, ref } from "vue";
import DocumentEditorModel from "../model";
import TextInput from "./TextInput/TextInput.vue";
import { debounce } from "@/utils/utils";
import { ColumnItemType } from "../ContentManager/Paginator/Page/Column/ColumnItem/model";

const { model } = defineProps<{
  model: DocumentEditorModel;
}>();

const items_selected_metadata = computed(() => {
  let has_signs = false;

  if (model.state.items.selected.length > 0) {
    model.state.items.selected.forEach((item) => {
      model.contentManager.content.find((content) => {
        if (content.id === item) {
          if (content.type === ColumnItemType.SIGN) {
            has_signs = true;
          }
        }
      });
    });
  }

  return {
    has_signs,
  };
});

const zoom = ref(100);
const swatches = [
  [
    "#FF0000", // red
    "#AA0000", // dark red
    "#550000", // dark dark red
  ],
  [
    "#FFFF00", // yellow
    "#AAAA00", // dark yellow
    "#555500", // dark dark yellow
  ],
  [
    "#00FF00", // green
    "#00AA00", // dark green
    "#005500", // dark dark green
  ],
  [
    "#00FFFF", // cyan
    "#00AAAA", // dark cyan
    "#005555", // dark dark cyan
  ],
  [
    "#0000FF", // blue
    "#0000AA", // dark blue
    "#000055", // dark dark blue
  ],
];

/**
 * In JavaScript, when a method is passed as a callback, it loses its original context.
 * The loss of the original context will cause the method to lose access to the instance properties and methods.
 * */
const boundUpdateSignsColor = model.contentManager.updateSignsColor.bind(
  model.contentManager,
);
const debounceSignsColorChange = debounce(boundUpdateSignsColor, 100);

function signEditorUpdate() {
  const selectedSigns = model.contentManager.content.filter(
    (sign) =>
      model.state.items.selected.includes(sign.id) &&
      sign.type === ColumnItemType.SIGN,
  );
  model.signEditor().activate("update", selectedSigns);
}
</script>
<template>
  <div class="control-plane">
    <div class="control-plane__row">
      <div class="control-plane__quick-commands">
        <div class="quick-commands__section">
          <div class="zoom">
            <v-select
              variant="plain"
              width="max-content"
              density="compact"
              hide-details
              :items="[
                { title: '25%', value: 25 },
                { title: '50%', value: 50 },
                { title: '75%', value: 75 },
                { title: '90%', value: 90 },
                { title: '100%', value: 100 },
                { title: '125%', value: 125 },
                { title: '150%', value: 150 },
                { title: '200%', value: 200 },
              ]"
              v-model="zoom"
              @update:model-value="(value) => model.setZoom(value)"
            >
            </v-select>
          </div>
          <div class="history" v-if="false">
            <v-btn
              icon="rotate-left"
              variant="plain"
              density="compact"
              @click="model.undo()"
              :disabled="!model.canUndo"
            />
            <v-btn
              icon="rotate-right"
              density="compact"
              variant="plain"
              @click="model.redo()"
              :disabled="!model.canRedo"
            />
          </div>
          <div class="show-non-printable">
            <v-btn
              :icon="
                model.state.items.showNonPrintableCharacters
                  ? 'eye'
                  : 'eye-slash'
              "
              size="small"
              variant="plain"
              density="compact"
              @click="model.toggleNonPrintableCharacters()"
            />
          </div>
        </div>
        <div class="quick-commands__section desktop">
          <v-btn
            icon="plus"
            size="small"
            variant="plain"
            density="compact"
            @click="model.signEditor().activate('create')"
          />
        </div>
        <div
          class="quick-commands__section"
          v-show="items_selected_metadata.has_signs"
        >
          <div class="change-sign-color">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn
                  icon="palette"
                  size="small"
                  variant="plain"
                  density="compact"
                  v-bind="props"
                />
              </template>
              <v-list>
                <v-list-item>
                  <v-color-picker
                    class="color-picker"
                    :modes="['hex']"
                    hide-canvas
                    hide-inputs
                    show-swatches
                    :swatches="swatches"
                    @update:model-value="
                      (value) =>
                        debounceSignsColorChange(
                          model.state.items.selected,
                          value,
                        )
                    "
                  />
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div
          class="quick-commands__section"
          v-show="items_selected_metadata.has_signs"
        >
          <v-btn
            icon="pen"
            size="small"
            variant="plain"
            density="compact"
            @click="signEditorUpdate"
          />
        </div>
      </div>
    </div>
    <div class="control-plane__row">
      <TextInput />
    </div>
  </div>
</template>
<style scoped lang="scss">
.control-plane {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .control-plane__row {
    width: 100%;

    &:first-of-type {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    &:last-of-type {
      margin-bottom: 1rem;
    }

    .control-plane__quick-commands {
      display: flex;
      width: 85%;
      max-height: 4rem;
      padding: 0 0.5rem;
      margin: auto;
      background-color: rgba(11, 87, 208, 0.2);
      border-radius: 4px;
      gap: 1rem;

      .quick-commands__section {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding: 0.3rem;
      }
    }
  }
}
</style>
