export default class FeatureToggle {
  static get environment(): string {
    return import.meta.env.MODE;
  }

  static isSignMakerEnabled(): boolean {
    return this.environment === "development" || this.environment === "dev";
  }

  static isSettingsEnabled(): boolean {
    return this.environment === "development" || this.environment === "dev";
  }

  static isSettingsLanguageEnabled(): boolean {
    return this.environment === "development" || this.environment === "dev";
  }

  static isSettingsThemeEnabled(): boolean {
    return this.environment === "development" || this.environment === "dev";
  }

  static isLazyLoadingI18nMessagesEnabled(): boolean {
    return this.environment === "development" || this.environment === "dev"
      ? true
      : false;
  }
}
