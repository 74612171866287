import { info } from "@sutton-signwriting/core/fsw/fsw";
import ColumnItem, { ColumnItemType } from "../model";
import type { Component } from "vue";
import SignComponent from "../SignComponent/SignComponent.vue";

export default class Sign extends ColumnItem {
  private _fsw: string | undefined;

  constructor(content: object = {}) {
    super(content);
  }

  protected _getComponent(): Component {
    return SignComponent;
  }
  protected _getType(): ColumnItemType {
    return ColumnItemType.SIGN;
  }
  protected _getParametersFromContent(): object {
    const parameters = {};

    this._fsw =
      "fsw" in this.content ? (this.content as any)["fsw"] : undefined;

    if (this._fsw) {
      const normalizedFsw = this._standardizeFswStyle(this._fsw);
      Object.assign(parameters, { fsw: normalizedFsw });
    }

    return parameters;
  }

  protected _getWidth(): number {
    let width = 0;

    if (this._fsw) {
      const normalizedFsw = this._standardizeFswStyle(this._fsw);
      const signInfo = info(normalizedFsw);
      width = signInfo.width;
    }

    return width;
  }

  protected _getContentHeight(): number {
    let height = 0;

    if (this._fsw) {
      const normalizedFsw = this._standardizeFswStyle(this._fsw);
      const signInfo = info(normalizedFsw);
      height =
        signInfo.height - (signInfo.height - signInfo.height * signInfo.zoom);
    }

    return height;
  }

  protected _getPaddingTop(): number {
    return 15;
  }

  protected _getPaddingBottom(): number {
    return 15;
  }

  private _standardizeFswStyle(fsw: string): string {
    let enrichedFsw = fsw;
    if (null === fsw) return fsw;
    if (fsw.includes("Z")) return fsw;

    // It has other styles applied, no need to put the dash before the Z
    if (fsw.includes("-")) {
      enrichedFsw = fsw + "Z0.7";
    }

    // It has no styles applied, put the dash before the Z
    if (!fsw.includes("-")) {
      enrichedFsw = fsw + "-Z0.7";
    }

    return enrichedFsw;
  }
}
