import {
  SignPuddle3Client,
  type ApiConfigurations,
  type SignPuddlePayload,
  type SignPuddleSearchEndPointResult,
} from "@/api/SignPuddle3Client";
import { Caret } from "./Caret/model";
import Paginator from "./Paginator/model";
import documentEditorState from "../state";
import ColumnItemFactory from "./Paginator/Page/Column/ColumnItem/ColumnItemFactory";
import ColumnItem, {
  ColumnItemType,
} from "./Paginator/Page/Column/ColumnItem/model";
import { LocalHostClient } from "@/api/localHostClient";

export default class ContentManager {
  content: ColumnItem[];
  paginator: Paginator;
  caret: Caret;
  private _columnItemFactory: typeof ColumnItemFactory;
  private _signPuddle3Client: SignPuddle3Client;
  private _localHostClient: LocalHostClient;
  private _documentId: string;

  constructor(configurations: {
    content: ColumnItem[];
    documentId?: string;
    paginator?: Paginator;
    caret?: Caret;
    columnItemFactory?: typeof ColumnItemFactory;
    signPuddle3Client?: SignPuddle3Client;
    localHostClient?: LocalHostClient;
  }) {
    this.content = configurations.content;
    this.paginator = configurations.paginator || new Paginator();
    this.caret =
      configurations.caret ||
      new Caret({
        state: documentEditorState.caret,
      });
    this._columnItemFactory =
      configurations.columnItemFactory || ColumnItemFactory;
    this._signPuddle3Client =
      configurations.signPuddle3Client || new SignPuddle3Client();
    this._localHostClient =
      configurations.localHostClient || new LocalHostClient();
    this._documentId = configurations.documentId || "";
  }

  paginate() {
    this.paginator.init(this.content);
    this.caret.init(this.paginator.paginatedItems);
    this.saveContent();
  }

  addItem(type: ColumnItemType, content: {} = {}): ColumnItem | undefined {
    const currentItem = this.caret.state.target;
    if (!currentItem) {
      console.debug("ContentManager: addItem: No caret target.");
      return;
    }

    const newColumnItem = this._columnItemFactory.createColumnItem(
      type,
      content,
    );

    if (this.caret.state.position.beforeTarget) {
      // Add item before target.
      this.content.splice(currentItem.documentIndex!, 0, newColumnItem);
    }

    if (this.caret.state.position.afterTarget) {
      // Add item after target.
      this.content.splice(currentItem.documentIndex! + 1, 0, newColumnItem);
    }

    this.paginate();

    // Decides caret position after adding NonTargetableItem.
    if (this.caret.isNonTargetabbleItem(newColumnItem.type!)) {
      let nextItem = this.caret.getNextPaginatedItemById(newColumnItem.id);

      // If the next item is a NonTargetableItem and not the first item in the column, move to the next item.
      while (
        this.caret.isNonTargetabbleItem(nextItem.type!) &&
        nextItem.column.itemIndex !== 0
      ) {
        nextItem = this.caret.getNextPaginatedItemById(nextItem.id);
      }

      if (nextItem.column.itemIndex === 0) {
        this.caret.moveToTarget(nextItem.id!);
        this.caret.beforeTarget();
        return newColumnItem;
      }
    }

    // After verifying the next item is not a NonTargetableItem,
    // move to the next item and place the caret after it.
    this.caret.moveToTarget(newColumnItem.id!);
    this.caret.afterTarget();
    return newColumnItem;
  }

  /**
   * Deletes the item before the caret target.
   *
   */
  deleteItemBackward(): void {
    const currentCaretItem = this.caret.state.target;
    if (!currentCaretItem) {
      console.debug("ContentManager: deleteItemBackward: No caret target.");
      return;
    }

    if (this.caret.state.position.beforeTarget) {
      if (currentCaretItem.documentIndex === 0) {
        console.debug(
          "ContentManager: deleteItem: Caret target is first item and position is on top, deletion not allowed.",
        );
        return;
      }

      const previousItem = this.caret.itemBeforeTarget;
      if (!previousItem) {
        console.debug(
          "ContentManager: deleteItem: Caret is beforeTarget but no previous item found.",
        );
        return;
      }

      let newTarget = previousItem;

      if (this.caret.isNonTargetabbleItem(newTarget.type!)) {
        while (
          this.caret.isNonTargetabbleItem(newTarget.type!) &&
          newTarget.documentIndex !== 0
        ) {
          newTarget = this.caret.getPreviousPaginatedItemById(newTarget.id);
        }
      }

      this.content.splice(previousItem.documentIndex, 1);

      this.paginate();

      this.caret.moveToTarget(this.content[newTarget.documentIndex].id);
      this.caret.afterTarget();
      return;
    }

    if (this.caret.state.position.afterTarget) {
      if (currentCaretItem.documentIndex === 0) {
        this.content.splice(currentCaretItem.documentIndex, 1)[0];
        this.paginate();
        return;
      }

      if (this.caret.itemBeforeTarget) {
        const previousItem = this.caret.itemBeforeTarget;
        if (!previousItem) {
          console.debug(
            "ContentManager: deleteItem > itemBeforeTarget: No previous item.",
          );
          return;
        }

        this.content.splice(currentCaretItem.documentIndex, 1)[0];
        this.paginate();
        this.caret.moveToTarget(previousItem.id!);
        this.caret.afterTarget();
      }
    }
  }

  /**
   * Deletes the item after the caret target.
   *
   */
  deleteItemForward(): void {
    if (!this.caret.state.target) {
      console.debug("ContentManager: deleteItemForward: No caret target.");
      return;
    }

    if (this.caret.state.position.beforeTarget) {
      if (this.caret.state.target.documentIndex === 0) {
        this.content.splice(this.caret.state.target.documentIndex, 1)[0];
        this.paginate();
        return;
      }
    }

    if (this.caret.state.position.afterTarget) {
      const currentCaretItem = this.caret.state.target;
      const nextItem = this.caret.itemAfterTarget;
      if (!nextItem) {
        console.debug("ContentManager: deleteItemForward: No next item found.");
        return;
      }

      this.content.splice(nextItem.documentIndex, 1)[0];
      this.paginate();
      this.caret.moveToTarget(currentCaretItem.id!);
      this.caret.afterTarget();
    }
  }

  deleteItems(ids: string[]) {
    let newTargetId = "";

    const previousCaretItemId = this.caret.itemBeforeTarget?.id;
    if (previousCaretItemId) {
      newTargetId = previousCaretItemId;
    }

    const nextCaretItemId = this.caret.itemAfterTarget?.id;
    if (nextCaretItemId) {
      newTargetId = nextCaretItemId;
    }

    this.content = this.content.filter((item) => !ids.includes(item.id));
    this.paginate();
    this.caret.moveToTarget(newTargetId);
  }

  updateItem(id: string, newType: ColumnItemType, content: object) {
    const itemIndex = this.content.findIndex((item) => item.id === id);
    if (itemIndex === -1) {
      console.debug(
        `ContentManager: updateItem: Item with id ${id} not found.`,
      );
      return;
    }

    const item = this.content[itemIndex];
    const newContent = { ...item.content, ...content };

    if (newType !== item.type) {
      /**
       * A new object must be instantiated to change the Proxy target and trigger Vue reactivity.
       * Without this, the old class instance will not be replaced, and changes won't be reflected.
       *
       * Note: Updating parameters within the same class (e.g., Fetching to Fetching) is not an issue.
       * However, switching between different classes (e.g., Fetching to Sign) requires a new instance.
       */
      const newItem = this._columnItemFactory.createColumnItem(
        newType,
        newContent,
      );
      newItem.id = item.id;
      newItem.type = newType;
      this.content.splice(itemIndex, 1, newItem);
    } else {
      item.reset(newContent);
    }

    const currentCaretItemId = this.caret.state.target?.id;
    const currentCaretPosition = this.caret.state.position.afterTarget;

    this.paginate();

    this.caret.moveToTarget(currentCaretItemId!);
    currentCaretPosition ? this.caret.afterTarget() : this.caret.beforeTarget();
  }

  updateSignsColor(ids: string[], color: string) {
    ids.forEach((id) => {
      this._updateSignColor(id, color);
    });
  }

  translateTextIntoSigns(text: string, apiConfigurations: ApiConfigurations) {
    // Regular expression to match words and quoted phrases
    const regex = /"([^"]+)"|(\S+)/g;
    const words = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
      // If the match is a quoted phrase, use the first capturing group
      // Otherwise, use the second capturing group
      words.push(match[1] || match[2]);
    }

    const newItems: ColumnItem[] = [];

    words.forEach((word) => {
      const columnItem = this.addItem(ColumnItemType.FETCHING, {
        terms: word,
        status: "loading",
      });
      newItems.push(columnItem!);
    });

    newItems.forEach(async (item) => {
      try {
        const apiResult = await this._signPuddle3Client.getSignsByTerm(
          (item.content as any).terms,
          apiConfigurations,
        );

        if (!apiResult.results || apiResult.results.length === 0) {
          this.updateItem(item.id, ColumnItemType.FETCHING, {
            terms: (item.content as any).terms,
            status: "error",
          });
          return;
        }

        const sign = apiResult.results[0].sign;
        const signtext = apiResult.results[0].signtext;

        if (sign && sign.length > 0) {
          this.updateItem(item.id, ColumnItemType.SIGN, {
            terms: apiResult.results[0].terms,
            text: apiResult.results[0].text,
            source: apiResult.results[0].source,
            fsw: sign,
          });
        }

        if (signtext && signtext.length > 0) {
          this._addMultipleSigns(item, apiResult);
        }
      } catch (error) {
        console.error(`Error processing item ${item.id}:`, error);
        this.updateItem(item.id, ColumnItemType.FETCHING, {
          terms: (item.content as any).terms,
          status: "error",
        });
      }
    });
  }

  moveItem(itemId: string, targetId: string, position: "before" | "after") {
    const itemIndex = this.content.findIndex((item) => item.id === itemId);
    const targetIndex = this.content.findIndex((item) => item.id === targetId);

    const item = this.content[itemIndex];
    this.content.splice(itemIndex, 1);
    if (position === "before") this.content.splice(targetIndex, 0, item);
    if (position === "after") this.content.splice(targetIndex + 1, 0, item);

    this.paginate();

    this.caret.moveToTarget(item.id);
    this.caret.afterTarget();
  }

  moveToLastOfPage(pageNumber: number) {
    this.caret.moveToLastOfPage(pageNumber);
  }

  /**
   * Moves the caret to before the target item.
   *
   * @param targetId - The id of the target item.
   */
  clickBeforeItem(targetId: string) {
    this.caret.moveToTarget(targetId);
    this.caret.beforeTarget();
    this.caret.show();
    this.caret.activate();
  }

  /**
   * Moves the caret to after the target item.
   *
   * @param targetId - The id of the target item.
   */
  clickAfterItem(targetId: string) {
    this.caret.moveToTarget(targetId);
    this.caret.afterTarget();
    this.caret.show();
    this.caret.activate();
  }

  saveContent() {
    this._localHostClient.saveDocumentContent(this._documentId, this.content);
  }

  private _addMultipleSigns(
    item: ColumnItem,
    apiResult: SignPuddlePayload<SignPuddleSearchEndPointResult>,
  ) {
    const fswsFromSigntext = apiResult.results[0].signtext.split(" ");
    let recentlyAddedSignId: string = "";

    fswsFromSigntext.forEach((fsw, index) => {
      if (index === 0) {
        this.updateItem(item.id, ColumnItemType.SIGN, {
          terms: apiResult.results[0].terms,
          text: apiResult.results[0].text,
          source: apiResult.results[0].source,
          fsw: fsw,
        });
        recentlyAddedSignId = item.id;
        return;
      }

      const sign = ColumnItemFactory.createColumnItem(ColumnItemType.SIGN, {
        terms: apiResult.results[0].terms + " (" + (index + 1) + ")",
        text: apiResult.results[0].text,
        source: apiResult.results[0].source,
        fsw,
      });

      const recentlyAddedSignIndex = this.content.findIndex(
        (item) => item.id === recentlyAddedSignId,
      );
      this.content.splice(recentlyAddedSignIndex + 1, 0, sign);
      recentlyAddedSignId = sign.id;
    });

    this.paginate();
    this.caret.moveToTarget(recentlyAddedSignId);
    this.caret.afterTarget();
  }

  private _updateSignColor(id: string, color: string) {
    const item = this.content.find((item) => item.id === id);
    if (!item || item.type !== ColumnItemType.SIGN) return;

    let itemFsw = (item.parameters as any).fsw;

    if (!this._isHexColor(color)) {
      console.debug(
        "SignComponent(" +
          id +
          "): model: _updateColor: Invalid color format, it's not HEX: " +
          color,
      );
      return;
    } else {
      color = color.substring(1); // Remove the "#" character
    }

    if (!itemFsw) {
      console.debug(
        "ContentManager: updateSignColor: No FSW found in item " +
          id +
          " parameters.",
      );
      return;
    }

    if (itemFsw.includes("-")) {
      const splitFsw = itemFsw.split("-");

      if (splitFsw.length === 2) {
        const style = splitFsw[1];

        if (style.includes("D")) {
          itemFsw = itemFsw.replace(/D_[A-Za-z0-9]+_/g, "D_" + color + "_");
        } else {
          itemFsw = splitFsw[0] + "-D_" + color + "_" + style;
        }
      }
    } else {
      itemFsw = itemFsw + "-D_" + color + "_";
    }

    this.updateItem(id, ColumnItemType.SIGN, {
      ...item?.content,
      fsw: itemFsw,
    });
  }

  private _isHexColor(value: string): boolean {
    const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    return hexColorRegex.test(value);
  }
}
