<script setup lang="ts">
import {
  computed,
  reactive,
  ref,
  watch,
  type ComponentPublicInstance,
  type Ref,
} from "vue";
import type ColumnItem from "./model";
import { columnItemEvent } from "./eventBus";
import type { ColumnItemType } from "./model";
import documentEditorState from "@/views/DocumentEditor/state";

const props = defineProps<{
  item: ColumnItem;
}>();

function handleEvent(config: {
  event: "click" | "dblclick" | "drop";
  id: string;
  type: ColumnItemType;
  content?: object;
  at?: "bottom" | "top";
  itemDroppedId?: string;
}) {
  columnItemEvent.emit("columnItem", {
    id: config.id,
    type: config.type,
    content: config.content || {},
    event: config.event,
    at: config.at,
    itemDroppedId: config.itemDroppedId,
  });
}

const hasCaret = computed(() => {
  return (
    documentEditorState.caret.isVisible &&
    documentEditorState.caret.target?.id === props.item.id
  );
});

const columnItemRef: Ref<Element | ComponentPublicInstance | null> = ref(null);

function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function putItemOnTheVisibleArea() {
  const element = columnItemRef.value as HTMLElement;
  if (!isElementInViewport(element)) {
    element.scrollIntoView({
      block: "center",
      inline: "nearest",
    });
  }
}

watch(
  () => hasCaret.value,
  (newValue) => {
    if (newValue && columnItemRef.value) {
      putItemOnTheVisibleArea();
    }
  },
);

function clickOnTop() {
  handleEvent({
    event: "click",
    id: props.item.id,
    type: props.item.type,
    at: "top",
  });
}

function clickOnBottom() {
  handleEvent({
    event: "click",
    id: props.item.id,
    type: props.item.type,
    at: "bottom",
  });
}

function dblclick() {
  handleEvent({
    event: "dblclick",
    id: props.item.id,
    type: props.item.type,
  });
}

let isDragging = ref(false);

function mouseDown(event: MouseEvent) {
  event.stopPropagation();
  isDragging.value = true;
  document.addEventListener("mouseup", mouseUp);
}

function mouseUp() {
  isDragging.value = false;
  document.removeEventListener("mousedown", mouseDown);
  document.removeEventListener("mouseup", mouseUp);
}

const dragEnter = reactive({
  top: false,
  bottom: false,
});

function dragStart(event: DragEvent) {
  event.dataTransfer?.setData("text/plain", props.item.id);
}

function drop(event: DragEvent) {
  event.preventDefault();
  const data = event.dataTransfer?.getData("text");
  if (!data) return;

  handleEvent({
    event: "drop",
    id: props.item.id,
    type: props.item.type,
    at: dragEnter.top ? "top" : "bottom",
    itemDroppedId: data,
  });

  dragEnter.top = false;
  dragEnter.bottom = false;
}

function dragOver(event: DragEvent) {
  event.preventDefault();
}
</script>
<template>
  <div
    class="column-item"
    :style="{
      paddingTop: `${item.paddingTop}px`,
      paddingBottom: `${item.paddingBottom}px`,
    }"
    :ref="
      (el) => {
        columnItemRef = el;
      }
    "
    :draggable="isDragging"
    @dragstart="dragStart"
    @drop="drop"
    @dragover="dragOver"
    @mousedown="mouseDown"
    @dblclick="dblclick"
  >
    <span
      class="column-item__selected"
      v-show="documentEditorState.items.selected.includes(props.item.id)"
    ></span>
    <span
      class="column-item__cover top"
      :data-caret="
        hasCaret &&
        documentEditorState.caret.position.beforeTarget &&
        !isDragging
      "
      :data-caret-active="documentEditorState.caret.isActive"
      :data-dragEntered="dragEnter.top"
      @click="clickOnTop"
      @dragenter="
        (event) => {
          event.preventDefault();
          dragEnter.top = true;
        }
      "
      @dragleave="
        (event) => {
          event.preventDefault();
          dragEnter.top = false;
        }
      "
    ></span>
    <div
      class="column-item__content"
      :style="{
        height: `${item.contentHeight}px`,
      }"
    >
      <component :is="item.rawComponent" />
    </div>
    <span
      class="column-item__cover bottom"
      :data-caret="
        hasCaret &&
        documentEditorState.caret.position.afterTarget &&
        !isDragging
      "
      :data-caret-active="documentEditorState.caret.isActive"
      :data-dragEntered="dragEnter.bottom"
      @click="clickOnBottom"
      @dragenter="
        (event) => {
          event.preventDefault();
          dragEnter.bottom = true;
        }
      "
      @dragleave="
        (event) => {
          event.preventDefault();
          dragEnter.bottom = false;
        }
      "
    ></span>
  </div>
</template>
<style scoped lang="scss">
.column-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  user-select: none;
  cursor: text;

  .column-item__selected {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 255, 0.1);
  }

  .column-item__cover {
    position: absolute;
    display: block;
    width: 100%;
    height: 50%;

    &.top {
      top: 0;

      &[data-dragEntered="true"] {
        border-top: 2px solid rgba(0, 0, 255, 0.5);
      }

      &[data-caret="true"] {
        border-top: 1px solid black;

        &[data-caret-active="true"] {
          animation: pulseTop 1s infinite;

          @keyframes pulseTop {
            0% {
              border-top: none;
            }
            50% {
              border-top: 1px solid black;
            }
          }
        }
      }
    }

    &.bottom {
      bottom: 0;

      &[data-dragEntered="true"] {
        border-bottom: 2px solid rgba(0, 0, 255, 0.5);
      }

      &[data-caret="true"] {
        border-bottom: 1px solid black;

        &[data-caret-active="true"] {
          animation: pulseBottom 1s infinite;

          @keyframes pulseBottom {
            0% {
              border-bottom: none;
            }
            50% {
              border-bottom: 1px solid black;
            }
          }
        }
      }
    }
  }

  .column-item__content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media print {
  .column-item {
    .column-item__cover {
      display: none;
    }

    .column-item__actions {
      opacity: 0;
    }
  }
}
</style>
