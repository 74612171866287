import ColumnItemFactory from "./Page/Column/ColumnItem/ColumnItemFactory";
import ColumnItem, { ColumnItemType } from "./Page/Column/ColumnItem/model";
import Column from "./Page/Column/model";
import Page from "./Page/model";

export interface PaginatedItem {
  id: string | null;
  type: ColumnItemType | null;
  documentIndex: number; // Index of the item across the entire document

  page: {
    index: number; // Page number where the item is located
    itemIndex: number; // Item's position within the page
  };

  column: {
    index: number; // Column number within the page
    itemIndex: number; // Item's position within the column
  };
}

export default class Paginator {
  private _pages: Page[] = [];
  private _columns: Column[] = [];

  constructor(
    private pageDefaults: any = {
      width: 794,
      height: 1123,
      padding: {
        top: 20,
        left: 20,
        right: 20,
        bottom: 20,
      },
      orientation: "portrait",
      textOrientation: "vertical",
      backgroundColor: "white",
      border: "1px solid black",
      borderRadius: 5,
    },
  ) {
    this._reset();
  }

  init(content: ColumnItem[]) {
    this._reset();

    this._segregateContentIntoColumns(content);
    this._paginateColumns();
  }

  get pages() {
    return this._pages;
  }

  private _reset() {
    this._pages = [
      new Page(
        this.pageDefaults.width,
        this.pageDefaults.height,
        this.pageDefaults.padding,
        1,
      ),
    ];
    this._columns = [
      new Column(this._pages[0].contentWidth, this._pages[0].contentHeight),
    ];
  }

  get paginatedItems(): PaginatedItem[] {
    const items: PaginatedItem[] = [];
    let documentIndex = -1;

    this._pages.forEach((page, pageIndex) => {
      let pageItemIndex = -1;

      page.columns.forEach((column, columnIndex) => {
        column.content.forEach((item, itemWithinColumnIndex) => {
          documentIndex++;
          pageItemIndex++;
          items.push({
            id: item.id,
            type: item.type,
            documentIndex: documentIndex,
            column: {
              index: columnIndex,
              itemIndex: itemWithinColumnIndex,
            },
            page: {
              index: pageIndex,
              itemIndex: pageItemIndex,
            },
          });
        });
      });
    });
    return items.flat();
  }

  private _getCurrentColumn(): Column {
    return this._columns[this._columns.length - 1];
  }

  private _getCurrentPage(): Page {
    return this._pages[this._pages.length - 1];
  }

  private _segregateContentIntoColumns(content: ColumnItem[]) {
    if (content.length === 0) {
      // Add a placeholder column for caret navigation
      const placeholder = ColumnItemFactory.createColumnItem(
        ColumnItemType.PLACEHOLDER,
      );
      this._getCurrentColumn().addItem(placeholder);
      return;
    }

    content.forEach((item, index) => {
      if (this._getCurrentColumn().breakpage) {
        this._columns.push(
          new Column(
            this._getCurrentPage().contentWidth,
            this._getCurrentPage().contentHeight,
          ),
        );
        this._getCurrentColumn().addItem(item);
        return;
      }

      if (item.type === ColumnItemType.BREAK_FLOW) {
        this._getCurrentColumn().addItem(item);
        this._columns.push(
          new Column(
            this._getCurrentPage().contentWidth,
            this._getCurrentPage().contentHeight,
          ),
        );

        if (index === content.length - 1) {
          // Add a placeholder column for caret navigation
          const placeholder = ColumnItemFactory.createColumnItem(
            ColumnItemType.PLACEHOLDER,
          );
          this._getCurrentColumn().addItem(placeholder);
        }
        return;
      }

      const columnWillNotFitWithinCurrentPage = !(
        this._getCurrentColumn().hasHeightAvailable(item.height) &&
        this._getCurrentPage().availableWidth()
      );
      if (columnWillNotFitWithinCurrentPage) {
        this._columns.push(
          new Column(
            this._getCurrentPage().contentWidth,
            this._getCurrentPage().contentHeight,
          ),
        );
      }

      this._getCurrentColumn().addItem(item);
    });
  }

  private _addPage(parameters: {
    width?: number;
    height?: number;
    padding?: { top?: number; right?: number; bottom?: number; left?: number };
  }) {
    this._pages.push(
      new Page(
        parameters.width || this.pageDefaults.width,
        parameters.height || this.pageDefaults.height,
        {
          top: parameters.padding?.top || this.pageDefaults.padding.top,
          right: parameters.padding?.right || this.pageDefaults.padding.right,
          bottom:
            parameters.padding?.bottom || this.pageDefaults.padding.bottom,
          left: parameters.padding?.left || this.pageDefaults.padding.left,
        },
        this._pages.length + 1,
      ),
    );
  }

  private _paginateColumns() {
    this._columns.forEach((column, index) => {
      if (column.breakpage) {
        this._getCurrentPage().addColumn(column);
        this._addPage({});

        // Add a placeholder column to the new page for caret navigation
        if (index === this._columns.length - 1) {
          const placeholder = ColumnItemFactory.createColumnItem(
            ColumnItemType.PLACEHOLDER,
          );
          const column = new Column(
            this._getCurrentPage().contentWidth,
            this._getCurrentPage().contentHeight,
          );

          column.addItem(placeholder);
          this._getCurrentPage().addColumn(column);
        }
        return;
      }

      const columnIsTooLargeForCurrentPage = !(
        column.width <= this._getCurrentPage().availableWidth()
      );

      if (columnIsTooLargeForCurrentPage) {
        this._addPage({});
        this._getCurrentPage().addColumn(column);
        return;
      }

      this._getCurrentPage().addColumn(column);
    });
  }
}
