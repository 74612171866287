export default class HistoryManager {
  history: string[] = [];
  private _historyIndex = 0;
  private _historyLimit = 15;

  constructor() {}

  commit(content: any) {
    // If the limit of history is reached, remove the oldest item
    if (this._historyLimit === this.history.length) {
      this.history.shift();
      this._historyIndex--;
    }

    // If the current history index is not at the end of the history array,
    // remove all the items after the current index
    if (this._historyIndex < this.history.length - 1) {
      this.history = this.history.slice(0, this._historyIndex + 1);
    }

    this.history.push(JSON.stringify(content));
    this._historyIndex++;
  }

  undo() {
    if (this.canUndo) {
      this._historyIndex--;
      return JSON.parse(this.history[this._historyIndex]);
    }
    return null;
  }

  redo() {
    if (this.canRedo) {
      this._historyIndex++;
      return JSON.parse(this.history[this._historyIndex]);
    }
    return null;
  }

  get canUndo() {
    return this.history.length > 1 && this._historyIndex > 0;
  }

  get canRedo() {
    return (
      this.history.length > 1 && this._historyIndex < this.history.length - 1
    );
  }
}
