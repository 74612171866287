import ColumnItem, { ColumnItemType } from "../model";
import type { Component } from "vue";
import PlaceholderComponent from "./PlaceholderComponent.vue";

export default class PlaceHolder extends ColumnItem {
  constructor(content: object = {}) {
    super(content);
  }

  protected _getComponent(): Component {
    return PlaceholderComponent;
  }
  protected _getType(): ColumnItemType {
    return ColumnItemType.PLACEHOLDER;
  }
  protected _getParametersFromContent(): object {
    return {};
  }

  protected _getWidth(): number {
    return 0;
  }

  protected _getContentHeight(): number {
    return 0;
  }

  protected _getPaddingTop(): number {
    return 0;
  }

  protected _getPaddingBottom(): number {
    return 0;
  }
}
