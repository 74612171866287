<script setup lang="ts">
import SignWriting from "@/components/SignWriting/SignWriting.vue";
import { info } from "@sutton-signwriting/core/fsw/fsw";

type SignComponentProps = {
  fsw: string;
  fontSize?: number;
  color?: string;
};

const props = defineProps<SignComponentProps>();

function extractSignColumnPositionFromFsw(fsw: string): number {
  switch (info(fsw).lane) {
    case -1: // Left
      return 1;
    case 0: // Middle
      return 2;
    case 1: // Right
      return 3;
    default:
      return 2; // Middle
  }
}
</script>
<template>
  <div class="sign-component">
    <SignWriting
      :fsw="props.fsw"
      :style="`grid-column: ${extractSignColumnPositionFromFsw(props.fsw)};`"
    />
  </div>
</template>
<style scoped lang="scss">
.sign-component {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
</style>
