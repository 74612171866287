import ColumnItem, { ColumnItemType } from "../model";
import type { Component } from "vue";
import BreakPageComponent from "./BreakPage.vue";

export default class BreakPage extends ColumnItem {
  constructor(content: object = {}) {
    super(content);
  }

  protected _getComponent(): Component {
    return BreakPageComponent;
  }
  protected _getType(): ColumnItemType {
    return ColumnItemType.BREAK_PAGE;
  }

  protected _getParametersFromContent() {
    return {};
  }

  protected _getWidth(): number {
    return 0;
  }

  protected _getContentHeight(): number {
    return 0;
  }

  protected _getPaddingTop(): number {
    return 15;
  }

  protected _getPaddingBottom(): number {
    return 15;
  }
}
