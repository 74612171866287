import { type Emitter } from "mitt";
import { onUnmounted } from "vue";

type EventHandlers<T extends Record<string, unknown>> = {
  [K in keyof T]: (event: T[K]) => void;
};

function useMittEvents<T extends Record<string, unknown>>(
  mitt: Emitter<T>,
  handlers: EventHandlers<T>,
) {
  for (const key of Object.keys(handlers)) {
    mitt.on(key, handlers[key]);
  }
  function cleanup() {
    for (const key of Object.keys(handlers)) {
      mitt.off(key, handlers[key]);
    }
  }
  onUnmounted(cleanup);
  return cleanup;
}

export function wrapEventBus<T extends Record<string, unknown>>(
  mitt: Emitter<T>,
) {
  return (handlers: EventHandlers<T>) => useMittEvents(mitt, handlers);
}
