<script setup lang="ts">
import { reactive } from "vue";

import SignMaker from "./SignMaker/SignMaker.vue";
import { signEditorEventBus } from "./eventBus";

import SignWriting from "@/components/SignWriting/SignWriting.vue";
import Sign from "../ContentManager/Paginator/Page/Column/ColumnItem/SignComponent/model";

import { info } from "@sutton-signwriting/core/fsw/fsw";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emit = defineEmits({
  create: (fsw?: string, terms?: string, text?: string, source?: string) =>
    true,
  update: (signs: Sign[]) => true,
  close: () => true,
});

type SignEditorState = {
  show: boolean;
  mode: "create" | "update" | undefined;
  sign: {
    collection: Sign[];
    selectedSign: {
      id: string;
      fsw: string;
      terms: string;
      text?: string;
      source?: string;
    };
  };
};

const state = reactive<SignEditorState>({
  show: false,
  mode: undefined,
  sign: {
    collection: [],
    selectedSign: {
      id: "",
      fsw: "",
      terms: "",
    },
  },
});

function updateSelectedSignFsw(metadata: {
  fsw?: string;
  terms?: string;
  text?: string;
  source?: string;
}) {
  if (metadata.fsw) {
    state.sign.selectedSign.fsw = metadata.fsw;
  }

  if (metadata.terms) {
    state.sign.selectedSign.terms = metadata.terms;
  }

  if (metadata.text) {
    state.sign.selectedSign.text = metadata.text;
  }

  if (metadata.source) {
    state.sign.selectedSign.source = metadata.source;
  }

  state.sign.collection = state.sign.collection.map((sign) => {
    if (sign.id === state.sign.selectedSign.id) {
      (sign.content as any).fsw = state.sign.selectedSign.fsw;
      (sign.content as any).terms = state.sign.selectedSign.terms;
      (sign.content as any).text = state.sign.selectedSign.text;
      (sign.content as any).source = state.sign.selectedSign.source;
    }
    return sign;
  });
}

function handleSignMakerSaveEvent(newFsw: string) {
  if (newFsw === "") {
    console.debug("SignEditor: save: empty fsw");
    return;
  }

  updateSelectedSignFsw({ fsw: newFsw });
}

function selectSign(sign: Sign) {
  state.sign.selectedSign = {
    id: sign.id,
    fsw: (sign.content as any).fsw,
    terms: (sign.content as any).terms,
    text: (sign.content as any).text,
    source: (sign.content as any).source,
  };
}

const rulesForFswInput = [
  (v: string) => !!v || t("signEditor.formulary.required"),
  (v: string) =>
    info(v).segment === "sign" || t("signEditor.formulary.invalid-fsw"),
];

const rulesForTermsInput = [
  (v: string) => !!v || t("signEditor.formulary.required"),
];

function areAllInputsValid() {
  return (
    rulesForFswInput.every(
      (rule) => rule(state.sign.selectedSign.fsw) === true,
    ) &&
    rulesForTermsInput.every(
      (rule) => rule(state.sign.selectedSign.terms) === true,
    )
  );
}

function reset() {
  state.mode = undefined;
  state.sign.collection = [];
  state.sign.selectedSign.id = "";
  state.sign.selectedSign.fsw = "";
  state.sign.selectedSign.terms = "";
  state.sign.selectedSign.text = "";
  state.sign.selectedSign.source = "";
}

function open() {
  reset();
  state.show = true;
}

function close() {
  state.show = false;
  emit("close");
  reset();
}

function apply() {
  if (!areAllInputsValid()) {
    console.debug("SignEditor: apply: invalid inputs");
    return;
  }

  if (state.mode === "create") {
    emit(
      "create",
      state.sign.selectedSign.fsw,
      state.sign.selectedSign.terms,
      state.sign.selectedSign.text,
      state.sign.selectedSign.source,
    );
  }

  if (state.mode === "update") {
    emit("update", state.sign.collection as Sign[]);
  }

  close();
}

signEditorEventBus({
  activate: ({ mode, signs }) => {
    open();

    switch (mode) {
      case "create":
        state.mode = "create";
        break;
      case "update":
        state.mode = "update";
        break;
      default:
        break;
    }

    if (!signs || signs.length === 0) {
      console.debug("SignEditor: activate: empty signs");
      return;
    }

    state.sign.collection = signs;
    state.sign.selectedSign = {
      id: signs[0].id,
      fsw: (signs[0].content as any).fsw,
      terms: (signs[0].content as any).terms,
      text: (signs[0].content as any).text,
      source: (signs[0].content as any).source,
    };
  },
});
</script>
<template>
  <v-overlay
    v-model="state.show"
    content-class="sign-editor__overlay"
    persistent
    :transition="false"
  >
    <div class="sign-editor__container">
      <div class="sign-editor__selected-signs" v-show="state.mode === 'update'">
        <SignWriting
          class="selected-signs__sign"
          :fsw="(sign.content as any).fsw"
          v-for="(sign, index) in state.sign.collection"
          :key="index"
          :font-size="0.5"
          @click="selectSign(sign as Sign)"
        />
      </div>
      <v-form class="sign-editor__formulary" @submit.prevent="apply">
        <div class="sign-editor__formulary-inputs">
          <div class="sign-editor__formulary-fsw-input">
            <p>
              {{ t("signEditor.formulary.inputs.fsw.label") }}&nbsp;<span
                style="color: red"
                >*</span
              >
            </p>
            <v-textarea
              variant="outlined"
              density="compact"
              v-model="state.sign.selectedSign.fsw"
              @update:model-value="
                (value) => updateSelectedSignFsw({ fsw: value })
              "
              :rules="rulesForFswInput"
              :placeholder="t('signEditor.formulary.inputs.fsw.placeholder')"
              hide-details
              required
              clearable
              auto-grow
              rows="3"
            />
            <div
              class="sign-editor__formulary-fsw-input-preview"
              v-show="state.mode == 'create'"
            >
              <div
                class="fsw-preview__no-preview"
                v-if="!(info(state.sign.selectedSign.fsw).segment === 'sign')"
              >
                {{ t("signEditor.formulary.no-preview") }}
              </div>
              <SignWriting :fsw="state.sign.selectedSign.fsw" v-else />
            </div>
          </div>
          <div>
            <p>
              {{ t("signEditor.formulary.inputs.terms.label") }}&nbsp;<span
                style="color: red"
                >*</span
              >
            </p>
            <v-text-field
              variant="outlined"
              density="compact"
              v-model="state.sign.selectedSign.terms"
              @update:model-value="
                (value) => updateSelectedSignFsw({ terms: value })
              "
              :placeholder="t('signEditor.formulary.inputs.terms.placeholder')"
              hide-details
              required
              clearable
              :rules="rulesForTermsInput"
            />
          </div>
          <div>
            <p>{{ t("signEditor.formulary.inputs.text.label") }}</p>
            <v-textarea
              variant="outlined"
              density="compact"
              v-model="state.sign.selectedSign.text"
              @update:model-value="
                (value) => updateSelectedSignFsw({ text: value })
              "
              :placeholder="t('signEditor.formulary.inputs.text.placeholder')"
              hide-details
              clearable
              auto-grow
              rows="3"
            />
          </div>
          <div>
            <p>{{ t("signEditor.formulary.inputs.source.label") }}</p>
            <v-text-field
              variant="outlined"
              density="compact"
              v-model="state.sign.selectedSign.source"
              @update:model-value="
                (value) => updateSelectedSignFsw({ source: value })
              "
              :placeholder="t('signEditor.formulary.inputs.source.placeholder')"
              hide-details
              clearable
            />
          </div>
        </div>
        <div class="sign-editor__formulary-actions">
          <v-btn
            :text="t('signEditor.formulary.actions.cancel')"
            @click="close"
          />
          <v-btn
            :text="t('signEditor.formulary.actions.save')"
            type="submit"
            :disabled="!areAllInputsValid()"
          />
        </div>
      </v-form>
      <div class="sign-editor__signmaker">
        <SignMaker
          :fsw="state.sign.selectedSign.fsw"
          @save="handleSignMakerSaveEvent"
          :style="{
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '.6rem',
          }"
        />
      </div>
    </div>
  </v-overlay>
</template>
<style lang="scss">
.sign-editor__overlay {
  position: relative;
  width: 100%;

  .sign-editor__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: max-content;
    height: 600px;
    padding: 1rem;
    display: flex;
    gap: 0.5rem;

    .sign-editor__selected-signs {
      width: 100px;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;
      padding: 0.5rem;
      gap: 1rem;
      background-color: white;
      border-radius: 5px;
      overflow-y: scroll;

      .selected-signs__sign {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 0.6rem;
        cursor: pointer;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }

    .sign-editor__signmaker {
      width: 600px;
    }

    .sign-editor__formulary {
      width: 240px;
      max-width: max-content;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.5rem;
      background-color: white;
      border-radius: 5px;
      overflow: scroll;

      .sign-editor__formulary-inputs {
        padding-top: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .sign-editor__formulary-fsw-input {
          .sign-editor__formulary-fsw-input-preview {
            display: none;
          }
        }
      }

      .sign-editor__formulary-actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 1rem;
        gap: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .sign-editor__overlay {
    .sign-editor__container {
      flex-direction: column;
      align-items: center;
      max-width: 300px;
      padding: 0;

      .sign-editor__selected-signs {
        width: 100%;
        flex-direction: row;
        max-height: max-content;
        order: 3;
      }

      .sign-editor__signmaker {
        width: 100%;
        order: 1;
      }

      .sign-editor__formulary {
        max-width: 100%;
        width: 100%;
        height: 100%;
        order: 2;

        .sign-editor__formulary-inputs {
          .sign-editor__formulary-fsw-input {
            .sign-editor__formulary-fsw-input-preview {
              display: block;
              margin-top: 0.5rem;
              padding: 0.5rem;
              border: 1px solid gainsboro;
              color: rgba(118, 96, 96, 0.74);

              .fsw-preview__no-preview {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>
