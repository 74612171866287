import ColumnItem, { ColumnItemType } from "../model";
import type { Component } from "vue";
import ImageComponent from "./ImageComponent.vue";

export default class Image extends ColumnItem {
  constructor(content: object = {}) {
    super(content);
  }

  private _width: string = "";
  private _height: string = "";

  protected _getComponent(): Component {
    return ImageComponent;
  }
  protected _getType(): ColumnItemType {
    return ColumnItemType.IMAGE;
  }
  protected _getParametersFromContent(): object {
    const parameters: any = {};

    const src =
      "src" in this.content ? (this.content as any)["src"] : undefined;
    if (src) {
      parameters.src = src;
    }

    const width =
      "width" in this.content ? (this.content as any)["width"] : undefined;
    if (width) {
      parameters.width = width;
      this._width = width;
    }

    const height =
      "height" in this.content ? (this.content as any)["height"] : undefined;
    if (height) {
      parameters.height = height;
      this._height = height;
    }

    const alt =
      "alt" in this.content ? (this.content as any)["alt"] : undefined;
    if (alt) {
      parameters.alt = alt;
    }

    return parameters;
  }

  protected _getWidth(): number {
    return parseInt(this._width);
  }

  protected _getContentHeight(): number {
    return parseInt(this._height);
  }

  protected _getPaddingTop(): number {
    return 15;
  }

  protected _getPaddingBottom(): number {
    return 15;
  }
}
