<script setup lang="ts">
type ImageComponentProps = {
  src: string;
  alt: string;
  width: string;
  height: string;
};

const props = defineProps<ImageComponentProps>();
</script>
<template>
  <div>
    <img
      :src="props.src"
      :alt="props.alt"
      :width="props.width"
      :height="props.height"
    />
  </div>
</template>
<style scoped lang="scss"></style>
