import BreakFlow from "./BreakFlow/model";
import BreakPage from "./BreakPage/model";
import Image from "./ImageComponent/model";
import Fetching from "./FetchingComponent/model";
import Sign from "./SignComponent/model";
import SignPunctuation from "./SignPunctuation/model";
import SpaceColumnItem from "./SpaceComponent/model";
import { ColumnItemType } from "./model";
import ColumnItem from "./model";
import PlaceHolder from "./PlaceholderComponent/model";

export default class ColumnItemFactory {
  static createColumnItem(type: ColumnItemType, content?: {}): ColumnItem {
    switch (type) {
      case ColumnItemType.SIGN:
        return new Sign(content);
      case ColumnItemType.SIGN_PUNCTUATION:
        return new SignPunctuation(content);
      case ColumnItemType.BREAK_FLOW:
        return new BreakFlow(content);
      case ColumnItemType.BREAK_PAGE:
        return new BreakPage(content);
      case ColumnItemType.SPACE:
        return new SpaceColumnItem(content);
      case ColumnItemType.FETCHING:
        return new Fetching(content);
      case ColumnItemType.IMAGE:
        return new Image(content);
      case ColumnItemType.PLACEHOLDER:
        return new PlaceHolder(content);
      default:
        throw new Error(`Unknown ColumnItemType: ${type}`);
    }
  }
}
