<script setup lang="ts">
import documentEditorState from "@/views/DocumentEditor/state";
import { computed } from "vue";

type BreakFlowProps = {
  show?: boolean;
};

const props = defineProps<BreakFlowProps>();
const show = computed(() =>
  props.show
    ? props.show
    : documentEditorState.items.showNonPrintableCharacters,
);
</script>
<template>
  <div class="breakflow">
    <v-icon icon="arrow-turn-up" size="x-small" v-show="show" />
  </div>
</template>
<style scoped lang="scss">
.breakflow {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 25px;
}
</style>
