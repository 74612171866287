import type { PaginatedItem } from "../Paginator/model";
import { ColumnItemType } from "../Paginator/Page/Column/ColumnItem/model";

export interface CaretState {
  isVisible: boolean;
  isActive: boolean;
  position: {
    beforeTarget: boolean;
    afterTarget: boolean;
  };
  target: PaginatedItem | undefined;
}

/**
 * Caret class to manage the caret position in the document.
 * It only knows the caret's visibility, activity, position, and targets.
 * It should not know the concept of paginatedItem addition, removal, or modification.
 */
export class Caret {
  private _items: PaginatedItem[] = [];
  readonly state: CaretState;

  constructor(config?: { items?: PaginatedItem[]; state?: CaretState }) {
    this._items = config?.items || [];
    this.state = config?.state || {
      isVisible: false,
      isActive: false,
      position: {
        beforeTarget: false,
        afterTarget: false,
      },
      target: undefined,
    };
  }

  init(items: PaginatedItem[]) {
    if (items.length === 0) {
      console.debug("Caret: No items to initialize");
      return;
    }

    this._items = items;
    this.moveToTarget(items[0].id!);
    this.show();
    this.activate();
    this.beforeTarget();
  }

  beforeTarget() {
    this.state.position.beforeTarget = true;
    this.state.position.afterTarget = false;
  }

  afterTarget() {
    this.state.position.beforeTarget = false;
    this.state.position.afterTarget = true;
  }

  forward() {
    if (!this.state.isActive) {
      console.debug(
        "Caret: forward: Caret is not active, aborting forward movement",
      );
      return;
    }

    if (!this.state.target) {
      console.debug(
        "Caret: forward: No target item, aborting forward movement",
      );
      return;
    }

    const nextPaginatedItem = this.getNextPaginatedItemById(
      this.state.target.id,
    );

    if (this.state.position.beforeTarget) {
      if (this.isNonTargetabbleItem(this.state.target.type!)) {
        if (nextPaginatedItem) {
          this.state.target = nextPaginatedItem;
        }
        return;
      }

      this.afterTarget();
      return;
    }

    if (this.state.position.afterTarget) {
      if (!nextPaginatedItem) {
        console.debug(
          "Caret: forward: No next item, aborting forward movement",
        );
        return;
      }

      if (this.isNonTargetabbleItem(nextPaginatedItem.type!)) {
        this.state.target = this.getNextPaginatedItemById(nextPaginatedItem.id);
        this.beforeTarget();
        return;
      }

      this.state.target = nextPaginatedItem;

      if (this.state.target.column.itemIndex === 0) {
        this.beforeTarget();
      }
      return;
    }
  }

  backward() {
    if (!this.state.isActive) {
      console.debug("Caret: backward: Caret is not active");
      return;
    }

    if (!this.state.target) {
      console.debug("Caret: backward: No target item");
      return;
    }

    let previousPaginatedItem = this.getPreviousPaginatedItemById(
      this.state.target.id,
    );

    if (this.state.position.beforeTarget) {
      if (!previousPaginatedItem) {
        console.debug("Caret: backward: Already at the start of the document");
        return;
      }

      if (
        this.isNonTargetabbleItem(this.state.target.type!) &&
        this.state.target.documentIndex !== 0
      ) {
        while (
          this.isNonTargetabbleItem(previousPaginatedItem.type!) &&
          previousPaginatedItem.column.itemIndex !== 0
        ) {
          previousPaginatedItem = this.getPreviousPaginatedItemById(
            previousPaginatedItem.id,
          );
        }

        this.state.target = previousPaginatedItem;

        if (
          this.state.target.column.itemIndex === 0 &&
          this.isNonTargetabbleItem(this.state.target.type!)
        ) {
          this.beforeTarget();
          return;
        }

        this.afterTarget();
        return;
      }

      if (this.isNonTargetabbleItem(previousPaginatedItem.type!)) {
        if (previousPaginatedItem.column.itemIndex === 0) {
          this.state.target = previousPaginatedItem;
          this.beforeTarget();
          return;
        }

        while (
          this.isNonTargetabbleItem(previousPaginatedItem.type!) &&
          previousPaginatedItem.column.itemIndex !== 0
        ) {
          previousPaginatedItem = this.getPreviousPaginatedItemById(
            previousPaginatedItem.id,
          );
        }

        this.state.target = previousPaginatedItem;
        this.afterTarget();
      }

      if (this.state.target?.column.itemIndex === 0) {
        this.state.target = previousPaginatedItem;
        this.afterTarget();
        return;
      }
    }

    if (this.state.position.afterTarget) {
      if (!previousPaginatedItem || this.state.target?.column.itemIndex === 0) {
        this.beforeTarget();
        return;
      }

      if (
        this.isNonTargetabbleItem(previousPaginatedItem.type!) &&
        previousPaginatedItem.column.itemIndex === 0
      ) {
        this.state.target = previousPaginatedItem;
        this.beforeTarget();
        return;
      }

      this.state.target = previousPaginatedItem;
    }
  }

  get itemBeforeTarget() {
    if (!this.state.target) {
      console.debug("Caret: itemBeforeTarget: No target item found");
      return;
    }

    const previousPaginatedItem = this.getPreviousPaginatedItemById(
      this.state.target.id,
    );
    if (!previousPaginatedItem) {
      console.debug("Caret: itemBeforeTarget: No previous item found");
      return;
    }

    return previousPaginatedItem;
  }

  get itemAfterTarget() {
    if (!this.state.target) {
      console.debug("Caret: itemAfterTarget: No target item found");
      return;
    }

    const nextPaginatedItem = this.getNextPaginatedItemById(
      this.state.target.id,
    );
    if (!nextPaginatedItem) {
      console.debug("Caret: itemAfterTarget: No next item found");
      return;
    }

    return nextPaginatedItem;
  }

  moveToTarget(targetId: string) {
    const target = this._getPaginatedItemById(targetId);

    if (!target) {
      console.debug("Caret: moveToTarget: Target item not found");
      return;
    }

    this.state.target = target;
  }

  moveToNextColumn() {
    if (!this.state.isActive) return;

    const currentColumnIndex = this.state.target?.column.index;
    const currentItemIndex = this.state.target?.column.itemIndex;
    const currentPageIndex = this.state.target?.page.index;

    let nextColumnItems = this._items.filter(
      (item) =>
        item.page.index === currentPageIndex &&
        item.column.index === currentColumnIndex! + 1,
    );

    if (nextColumnItems.length === 0) {
      // No next column, check for next page
      nextColumnItems = this._items.filter(
        (item) => item.page.index === currentPageIndex! + 1,
      );

      if (nextColumnItems.length === 0) return; // No next page either

      // Move to the start of the next page
      const targetItem = nextColumnItems[0];
      this.state.target = targetItem;
      this.beforeTarget();
      return;
    }

    const nextColumnItemCount = nextColumnItems.length;

    let targetItem;
    if (currentItemIndex! < nextColumnItemCount) {
      targetItem = nextColumnItems[currentItemIndex!];
    } else {
      targetItem = nextColumnItems[nextColumnItemCount - 1];
    }

    // TODO: If last item of next column is BREAK PAGE or BREAK FLOW, move to item before that
    if (targetItem) {
      this.state.target = targetItem;
    }
  }

  moveToPreviousColumn() {
    if (!this.state.isActive) return;

    const currentColumnIndex = this.state.target?.column.index;
    const currentItemIndex = this.state.target?.column.itemIndex;
    const currentPageIndex = this.state.target?.page.index;

    let previousColumnItems = this._items.filter(
      (item) =>
        item.page.index === currentPageIndex &&
        item.column.index === currentColumnIndex! - 1,
    );

    if (previousColumnItems.length === 0) {
      // No previous column, check for previous page
      previousColumnItems = this._items.filter(
        (item) => item.page.index === currentPageIndex! - 1,
      );

      if (previousColumnItems.length === 0) {
        console.debug(
          "Caret: moveToPreviousColumn: No previous column or page",
        );
        return;
      }

      // Move to the start of the previous page
      const targetItem = previousColumnItems[previousColumnItems.length - 1];
      this.state.target = targetItem;
      return;
    }

    const previousColumnItemCount = previousColumnItems.length;

    let targetItem;
    if (currentItemIndex! < previousColumnItemCount) {
      targetItem = previousColumnItems[currentItemIndex!];
    } else {
      targetItem = previousColumnItems[previousColumnItemCount - 1];
    }

    // TODO: If last item of previous column is BREAK PAGE or BREAK FLOW, move to item before that
    if (targetItem) {
      this.state.target = targetItem;
    }
  }

  moveToLastOfPage(pageNumber: number) {
    const pageItems = this._items.filter(
      (item) => item.page.index === pageNumber,
    );
    if (pageItems.length === 0) {
      console.debug(
        "Caret: moveToLastOfPage: No items found on the page: ",
        pageNumber,
      );
      return;
    }

    const lastOfPage = pageItems[pageItems.length - 1];
    if (!lastOfPage) {
      console.debug("Caret: moveToLastOfPage: No last item found");
      return;
    }

    if (this.isNonTargetabbleItem(lastOfPage.type!)) {
      this.state.target = lastOfPage;
      this.beforeTarget();
      return;
    }

    this.state.target = lastOfPage;
    this.afterTarget();
  }

  startOfColumn() {
    if (!this.state.isActive) return;

    if (!this.state.target) {
      console.debug("Caret: startOfColumn: No target item");
      return;
    }

    if (this.state.target.documentIndex === 0) {
      console.debug(
        "Caret: startOfColumn: Already at the start of the document",
      );
      return;
    }

    const currentColumnIndex = this.state.target.column.index;
    const currentPageIndex = this.state.target.page.index;

    const columnItems = this._items.filter(
      (item) =>
        item.page.index === currentPageIndex &&
        item.column.index === currentColumnIndex,
    );

    if (columnItems.length === 0) {
      console.debug("Caret: startOfColumn: No items in the column");
      return;
    }

    this.state.target = columnItems[0];
    this.beforeTarget();
  }

  endOfColumn() {
    if (!this.state.isActive) return;

    if (!this.state.target) {
      console.debug("Caret: startOfColumn: No target item");
      return;
    }

    if (this.state.target.documentIndex === this._items.length - 1) {
      console.debug("Caret: endOfColumn: Already at the end of the document");
      return;
    }

    const currentColumnIndex = this.state.target.column.index;
    const currentPageIndex = this.state.target.page.index;

    const columnItems = this._items.filter(
      (item) =>
        item.page.index === currentPageIndex &&
        item.column.index === currentColumnIndex,
    );

    if (columnItems.length === 0) {
      console.debug("Caret: endOfColumn: No items in the column");
      return;
    }

    this.state.target = columnItems[columnItems.length - 1];
    this.afterTarget();
  }

  activate() {
    this.state.isActive = true;
  }

  deactivate() {
    this.state.isActive = false;
  }

  show() {
    this.state.isVisible = true;
  }

  hide() {
    this.state.isVisible = false;
  }

  isNonTargetabbleItem(itemType: ColumnItemType) {
    return (
      itemType === ColumnItemType.BREAK_PAGE ||
      itemType === ColumnItemType.BREAK_FLOW ||
      itemType === ColumnItemType.PLACEHOLDER
    );
  }

  private _getPaginatedItemById(id: string | null) {
    if (!id) return;
    return this._items.find((item) => item.id === id);
  }

  getNextPaginatedItemById(id: string | null) {
    const index = this._items.findIndex((item) => item.id === id);
    return this._items[index + 1];
  }

  getPreviousPaginatedItemById(id: string | null) {
    const index = this._items.findIndex((item) => item.id === id);
    return this._items[index - 1];
  }
}
