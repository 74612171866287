import type ColumnItem from "@/views/DocumentEditor/ContentManager/Paginator/Page/Column/ColumnItem/model";
import type {
  PuddleInfo,
  SignPuddleMatch,
  SignPuddleSearchEndPointResult,
} from "./SignPuddle3Client";
import type { DocumentMetadata } from "@/views/DocumentEditor/model";
import ColumnItemFactory from "@/views/DocumentEditor/ContentManager/Paginator/Page/Column/ColumnItem/ColumnItemFactory";

export type UserPreferences = {
  country?: {
    code: string;
    name: string;
    puddle: PuddleInfo;
  };
  wordMatch?: SignPuddleMatch;
};

export class LocalHostClient {
  private _documentMetadataKey = "document_metadata_";
  private _documentContentKey = "document_content_";
  private _preSelectedSignKey = "preSelectedSign_";
  private _userPreferencesKey = "userPreferences";
  private _countryPuddlesKey = "countryPuddles_";

  createDocumentMetadata(info?: Partial<DocumentMetadata>): DocumentMetadata {
    let id = crypto.randomUUID();

    while (this.getDocumentMetadataById(id)) {
      id = crypto.randomUUID();
    }

    const newDocument: DocumentMetadata = {
      id: info?.id ?? id,
      title: info?.title ?? "",
      lastModified: info?.lastModified ?? "",
      createdAt: info?.lastModified ?? new Date().toString(),
    };
    localStorage.setItem(
      this._documentMetadataKey + newDocument.id,
      JSON.stringify(newDocument),
    );

    return newDocument;
  }

  getDocumentMetadataById(documentId: string): DocumentMetadata | null {
    const key = this._documentMetadataKey + documentId;
    const item = localStorage.getItem(key);

    if (!item) {
      console.debug(
        `LocalHostClient: getDocumentMetadataById: No document found for documentId: ${documentId}`,
      );
    }

    return item ? JSON.parse(item) : null;
  }

  deleteDocumentMetadataById(documentId: string): void {
    const key = this._documentMetadataKey + documentId;
    localStorage.removeItem(key);
  }

  /**
   * This function returns all documents from the local storage that have a key that follows the 'document_metadata' pattern.
   *
   * Example:
   *  Returns true if: document_
   *  Returns false if: documentMetadata
   *
   * @returns Array of documents
   */
  getDocumentsMetadata(): DocumentMetadata[] {
    const regex = /^document_metadata/;
    const keys = Object.keys(localStorage).filter((key) => regex.test(key));

    if (!keys.length) {
      console.debug(
        "LocalHostClient: getDocumentsMetadata: No documents found in local storage",
      );
    }

    return keys.map((key) => {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    });
  }

  /**
   * This function edits the metadata of a document in the local storage.
   * It does not replace the entire document, only the properties that are passed in the metadata object.
   *
   * @param documentId
   * @param metadata
   * @returns the edited document
   */
  editDocumentMetadata(
    documentId: string,
    metadata: Partial<DocumentMetadata>,
  ): void {
    const key = this._documentMetadataKey + documentId;
    const item = localStorage.getItem(key);

    if (!item) {
      return;
    }

    const document = JSON.parse(localStorage.getItem(key) || "");
    const newDocument = {
      ...document,
      ...metadata,
      lastModified: new Date().toString(),
    };
    localStorage.setItem(key, JSON.stringify(newDocument));

    console.debug(
      "LocalHostClient: editDocumentMetadata: Document metadata edited: ",
      newDocument,
    );

    return newDocument;
  }

  saveDocumentContent(documentId: string, content: object): void {
    localStorage.setItem(
      this._documentContentKey + documentId,
      JSON.stringify(content),
    );
  }

  getDocumentContentById(documentId: string): ColumnItem[] | null {
    const content = localStorage.getItem(this._documentContentKey + documentId);

    if (!content) {
      console.debug(
        `LocalHostClient: getDocumentContentById: No content found for documentId: ${documentId}`,
      );
      return null;
    }

    const parsedContent = JSON.parse(content);
    const parsedContentAsColumnItem = parsedContent.map((item: ColumnItem) => {
      return ColumnItemFactory.createColumnItem(item.type, item.content);
    });

    return parsedContentAsColumnItem;
  }

  /**
   * This function saves the SignPuddleSearchEndPointResult item in the local storage, under the key 'preSelectedSign_{term}'.
   *
   * @param term
   * @param signItem
   */
  saveSignPuddleSearchEndPointResultItem(
    term: string,
    signItem: SignPuddleSearchEndPointResult,
  ) {
    const key = this._preSelectedSignKey + term;
    localStorage.setItem(key, JSON.stringify(signItem));
  }

  /**
   * This function retrieves the SignPuddleSearchEndPointResult item from the local storage, under the key 'preSelectedSign_{term}'.
   *
   * @param term
   * @returns
   */
  getSignPuddleSearchEndPointResultItem(
    term: string,
  ): SignPuddleSearchEndPointResult | null {
    const key = this._preSelectedSignKey + term;
    const item = localStorage.getItem(key);

    if (!item) {
      console.debug(
        `LocalHostClient: getSignPuddleSearchEndPointResultItem: No item found for term: ${term}`,
      );
    }

    return item ? JSON.parse(item) : null;
  }

  getUserPreferences(): UserPreferences | null {
    const preferences = localStorage.getItem(this._userPreferencesKey);

    if (!preferences) {
      console.debug(
        "LocalHostClient: getUserPreferences: No preferences found in local storage",
      );
    }

    return preferences ? JSON.parse(preferences) : null;
  }

  setUserPreferences(preferences: UserPreferences) {
    localStorage.setItem(this._userPreferencesKey, JSON.stringify(preferences));
  }

  setCountryPuddles(country: string, puddles: PuddleInfo[]) {
    localStorage.setItem(
      this._countryPuddlesKey + country,
      JSON.stringify(puddles),
    );
  }

  getCountryPuddles(country: string): PuddleInfo[] | null {
    const puddles = localStorage.getItem(this._countryPuddlesKey + country);

    if (!puddles) {
      console.debug(
        `LocalHostClient: getCountryPuddles: No puddles found for country: ${country}`,
      );
    }

    return puddles ? JSON.parse(puddles) : null;
  }
}
