import { type Component, type Raw, markRaw } from "vue";
import { render } from "@/utils/utils";

export enum ColumnItemType {
  SIGN = "sign",
  SIGN_PUNCTUATION = "signPunctuation",
  BREAK_FLOW = "breakflow",
  BREAK_PAGE = "breakpage",
  SPACE = "space",
  FETCHING = "fetching",
  IMAGE = "image",
  PLACEHOLDER = "placeholder",
}

export default abstract class ColumnItem {
  id: string;
  type: ColumnItemType;
  width: number = 0;
  height: number = 0;
  paddingTop: number = 0;
  paddingBottom: number = 0;
  contentHeight: number = 0;
  parameters: object;
  content: object;
  rawComponent: Raw<Component>;

  constructor(content: object = {}) {
    this.content = content;
    this.id = this._getId();
    this.type = this._getType();
    this.parameters = this._getParametersFromContent();
    this.rawComponent = this._markRawComponent(this._getComponent());
    this.width = this._getWidth();
    this.contentHeight = this._getContentHeight();
    this.paddingTop = this._getPaddingTop();
    this.paddingBottom = this._getPaddingBottom();
    this.height = this.contentHeight + this.paddingTop + this.paddingBottom;
  }

  reset(content: object) {
    this.content = content;
    this.parameters = this._getParametersFromContent();
    this.rawComponent = this._markRawComponent(this._getComponent());
    this.width = this._getWidth();
    this.contentHeight = this._getContentHeight();
    this.paddingTop = this._getPaddingTop();
    this.paddingBottom = this._getPaddingBottom();
    this.height = this.contentHeight + this.paddingTop + this.paddingBottom;
  }

  protected _getId(): string {
    return crypto.randomUUID().slice(0, 7);
  }

  protected _markRawComponent(component: Component): Raw<Component> {
    return markRaw(render(component, this.parameters));
  }

  protected abstract _getComponent(): Component;

  protected abstract _getType(): ColumnItemType;

  protected abstract _getParametersFromContent(): object;

  protected abstract _getWidth(): number;

  protected abstract _getContentHeight(): number;

  protected abstract _getPaddingTop(): number;

  protected abstract _getPaddingBottom(): number;
}
