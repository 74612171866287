import { reactive } from "vue";
import type { CaretState } from "./ContentManager/Caret/model";

export interface DocumentEditorState {
  global: {
    elementOnFocus: "pages" | "textInput" | "signEditor";
    zoom: number;
    signMakerActivated: boolean;
  };
  caret: CaretState;
  items: {
    selected: string[];
    showNonPrintableCharacters: boolean;
  };
}

const caret: CaretState = {
  isVisible: false,
  isActive: false,
  position: {
    beforeTarget: false,
    afterTarget: false,
  },
  target: undefined,
};

const documentEditorState = reactive<DocumentEditorState>({
  global: {
    elementOnFocus: "pages",
    zoom: 100,
    signMakerActivated: false,
  },
  caret,
  items: {
    selected: [],
    showNonPrintableCharacters: false,
  },
});

export default documentEditorState;
