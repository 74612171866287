<script setup lang="ts">
import { type DocumentMetadata } from "@/views/DocumentEditor/model";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import appStore from "@/stores/AppStore";

const { t } = useI18n();

const { documentMetadata, deleteDocument, renameDocument } = defineProps<{
  documentMetadata: DocumentMetadata;
  deleteDocument: (document: DocumentMetadata) => void;
  renameDocument: (document: DocumentMetadata) => void;
}>();
const documentLastModified = computed(() => {
  let date;
  let status;

  if (documentMetadata.lastModified === "") {
    date = new Date(documentMetadata.createdAt);
    status = t("catalogItem.createdAt");
  } else {
    date = new Date(documentMetadata.lastModified);
    status = t("catalogItem.openAt");
  }

  if (date.toLocaleDateString() === new Date().toLocaleDateString()) {
    // if the hour is less than 10, it adds a 0 before the hour, same for the minutes.
    return (
      status +
      " " +
      (date.getHours() < 10 ? "0" : "") +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes()
    );
  }
  return status + " " + date.toLocaleDateString();
});
</script>
<template>
  <div class="catalog-item" :theme="appStore.state.theme.selected.value">
    <router-link
      :to="{ name: 'editor', query: { id: documentMetadata.id } }"
      class="router"
    >
      <div class="metadata">
        <div class="metadata__content">
          <p class="metadata__title">
            {{
              documentMetadata.title && documentMetadata.title.length > 0
                ? documentMetadata.title
                : t("document.defaultTitle")
            }}
          </p>
          <p class="metadata__lastModified">
            {{ documentLastModified }}
          </p>
        </div>
      </div>
    </router-link>
    <div class="menu">
      <v-menu location="end">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            icon="ellipsis-vertical"
            size="x-small"
            variant="text"
            color="black"
            max-width="27"
            max-height="27"
          >
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            prepend-icon="edit"
            :title="t('catalogItem.rename')"
            @click="renameDocument(documentMetadata)"
          />
          <v-list-item
            prepend-icon="trash"
            :title="t('catalogItem.delete')"
            @click="deleteDocument(documentMetadata)"
          />
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<style scoped lang="scss">
.catalog-item {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  overflow: hidden;

  .router {
    width: 100%;
    text-decoration: none;

    &:visited {
      color: inherit;
    }

    .metadata {
      .metadata__content {
        display: flex;
        flex-direction: column;
        padding: 0.7rem;

        .metadata__title {
          font-size: 0.9rem;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .metadata__lastModified {
          font-size: 0.7rem;
          font-weight: 400;
          color: rgba(99, 104, 108, 0.8);
        }
      }
    }
  }

  .menu {
    width: 3rem;
  }

  &[theme="dark"] {
    border-color: rgba(255, 255, 255, 0.2);

    .preview {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .metadata {
      border-top-color: rgba(255, 255, 255, 0.2);

      .metadata__content {
        .metadata__title {
          color: white;
        }

        .metadata__lastModified {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
}
</style>
