import { info } from "@sutton-signwriting/core/fsw/fsw";
import ColumnItem, { ColumnItemType } from "../model";
import type { Component } from "vue";
import SignPunctuationComponent from "./SignPunctuation.vue";

interface PunctuationFsw {
  vertical: string;
  horizontal: string;
}

const punctuationFsw: Record<string, PunctuationFsw> = {
  comma: {
    vertical: "S38700463x496", // , S38700463x496 Source: Escrita de Sinais Sem Mistério, page 185.
    horizontal: "S38702463x496", // ,
  },
  period: {
    vertical: "S38800463x496", // . S38800464x496  Source: Escrita de Sinais Sem Mistério, page 158.
    horizontal: "S38802463x496", // .
  },
  questionMark: {
    vertical: "S38900463x496", // ? S38900464x493  Source: Escrita de Sinais Sem Mistério, page 245.
    horizontal: "S38902463x496", // ?
  },
  exclamationMark: {
    vertical: "S38814463x496", // !  S38814463x495 Source: SignPuddle https://www.signbank.org/signpuddle2.0/canvas.php?ui=1&sgn=46&sid=19060
    horizontal: "S38816463x496", // !
  },
  openParenthesis: {
    vertical: "S38b00463x496", // ( S38b00470x493 Source: Escrita de Sinais Sem Mistério, page 284.
    horizontal: "S38b02463x496", // (
  },
  closeParenthesis: {
    vertical: "S38b04463x496", // ) S38b04470x493 Source: Escrita de Sinais Sem Mistério, page 284.
    horizontal: "S38b06463x496", // )
  },
  colon: {
    vertical: "S38a00463x496", // : S38a00464x490  Source: Escrita de Sinais Sem Mistério, page 185.
    horizontal: "S38a02463x496", // :
  },
};

export default class SignPunctuation extends ColumnItem {
  private _fsw: string | undefined = "";

  constructor(content: object = {}) {
    super(content);
  }

  protected _getComponent(): Component {
    return SignPunctuationComponent;
  }
  protected _getType(): ColumnItemType {
    return ColumnItemType.SIGN_PUNCTUATION;
  }
  protected _getParametersFromContent(): object {
    const parameters = new Map<string, any>();

    const type =
      "type" in this.content ? (this.content as any)["type"] : undefined;
    if (type) {
      this._fsw = punctuationFsw[type].vertical;
    }

    if (this._fsw) {
      const normalizedFsw = this._standardizeFswStyle(this._fsw);
      const signInfo = info(normalizedFsw);
      Object.assign(parameters, { fsw: this._fsw });
      Object.assign(parameters, { width: signInfo.width });
      Object.assign(parameters, { height: signInfo.height });
    }

    return parameters;
  }

  protected _standardizeFswStyle(fsw: string): string {
    let enrichedFsw = fsw;

    // Applies zoom to the sign if it is not already applied
    if (null != fsw && !fsw.includes("-Z")) {
      enrichedFsw = fsw + "-Z0.7";
    }
    return enrichedFsw;
  }

  protected _getWidth(): number {
    let width = 0;

    if (this._fsw) {
      const normalizedFsw = this._standardizeFswStyle(this._fsw);
      const signInfo = info(normalizedFsw);
      width = signInfo.width;
    }

    return width;
  }

  protected _getContentHeight(): number {
    let height = 0;

    if (this._fsw) {
      const normalizedFsw = this._standardizeFswStyle(this._fsw);
      const signInfo = info(normalizedFsw);
      height = signInfo.height;
    }

    return height;
  }

  protected _getPaddingTop(): number {
    return 15;
  }

  protected _getPaddingBottom(): number {
    return 15;
  }
}
