import { wrapEventBus } from "@/utils/eventBus";
import mitt from "mitt";
import type { AlertProps } from "./AlertComponent.vue";

// Event type
export type AppAlertEvent = {
  [addNotificationToAppAlertEvent: string]: AlertProps;
};

// Event emitter
export const addNotificationToAppAlertEvent = mitt<AppAlertEvent>();

// Event listener
export const addNotificationToAppAlertEventBus = wrapEventBus(
  addNotificationToAppAlertEvent,
);
