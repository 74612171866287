<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();

type SignMakerProps = {
  fsw: string;
  style?: {
    width?: string;
    height?: string;
    padding?: string;
    backgroundColor?: string;
    border?: string;
    borderRadius?: string;
  };
};

const props = defineProps<SignMakerProps>();
const emit = defineEmits(["save"]);

function listenToIframeMessages(event: any) {
  if (event.origin !== "https://www.sutton-signwriting.io") {
    console.debug("SignMaker: listenToIframeMessages: Invalid origin");
    return;
  }

  switch (event.data.signmaker) {
    case "save":
      emit("save", event.data.fsw);
      break;
    default:
      break;
  }
}

onMounted(() => {
  window.addEventListener("message", (event) => listenToIframeMessages(event));
});

onUnmounted(() => {
  window.removeEventListener("message", (event) =>
    listenToIframeMessages(event),
  );
});
</script>
<template>
  <div class="signmaker-container">
    <div
      class="desktop"
      :style="`background-color: ${props.style?.backgroundColor ?? 'white'}; border: ${props.style?.border}; border-radius: ${props.style?.borderRadius}; padding: ${props.style?.padding}`"
    >
      <v-alert
        type="info"
        density="compact"
        max-height="40px"
        :text="t('signMaker.saveHint')"
      >
        <template #prepend>
          <v-icon icon="info" size="xsmall" />
        </template>
      </v-alert>
      <iframe
        class="sign-maker"
        style="border: none"
        :src="`https://www.sutton-signwriting.io/signmaker/index.html#?fsw=${props.fsw}&ui=${locale === 'pt' ? 'ptBR' : 'en'}`"
      ></iframe>
      <div class="signmaker__footer">
        <p>
          SignMaker 2022 – {{ t("signMaker.poweredBy") }}
          <a
            href="https://www.sutton-signwriting.io/#sutton-signwritingsignmaker"
            >sutton-signwriting</a
          >.
        </p>
      </div>
    </div>
    <div class="mobile">
      <v-alert
        width="100%"
        type="info"
        density="compact"
        :text="t('signMaker.notAvailableOnMobile')"
      />
    </div>
  </div>
</template>
<style lang="scss">
.signmaker-container {
  width: 100%;
  height: 100%;

  .desktop {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .sign-maker {
      flex-grow: 1;
    }
  }

  .mobile {
    width: 100%;
    height: 100%;
    display: none;
  }

  .signmaker__footer {
    font-size: small;
  }
}

@media (max-width: 992px) {
  .signmaker-container {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
}
</style>
