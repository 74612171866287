import { createRouter, createWebHistory } from "vue-router";
import DocumentEditor from "@/views/DocumentEditor/DocumentEditor.vue";
import Catalog from "@/views/Catalog/CatalogView.vue";
import { LocalHostClient } from "@/api/localHostClient";
import DocumentEditorModel from "@/views/DocumentEditor/model";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "catalog",
      component: Catalog,
      props: {
        model: {
          client: new LocalHostClient(),
        },
      },
    },
    {
      path: "/editor",
      name: "editor",
      component: DocumentEditor,
      props: (route) => ({
        model: new DocumentEditorModel({
          id: route.query.id as string,
        }),
      }),
    },
  ],
});

export default router;
