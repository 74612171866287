import ColumnItem, { ColumnItemType } from "../model";
import type { Component } from "vue";
import FetchingComponent from "./FetchingComponent.vue";

export default class Fetching extends ColumnItem {
  constructor(content: object = {}) {
    super(content);
  }

  private _terms: string = "";
  private _status: string = "";

  protected _getComponent(): Component {
    return FetchingComponent;
  }
  protected _getType(): ColumnItemType {
    return ColumnItemType.FETCHING;
  }
  protected _getParametersFromContent(): object {
    const parameters: any = {};

    this._terms = "terms" in this.content ? (this.content as any)["terms"] : "";
    parameters.terms = this._terms;

    this._status =
      "status" in this.content ? (this.content as any)["status"] : "";
    parameters.status = this._status;

    return parameters;
  }

  protected _getWidth(): number {
    return 0;
  }

  protected _getContentHeight(): number {
    return 70;
  }

  protected _getPaddingTop(): number {
    return 5;
  }

  protected _getPaddingBottom(): number {
    return 5;
  }
}
