import { get } from "./client";

export enum SignPuddleMatch {
  Start = "start",
  End = "end",
  Exact = "exact",
  Anywhere = "any",
}

export type SignPuddlePayload<T> = {
  meta: {
    limit: number;
    offset: number;
    totalResults: number;
    location: string;
    searchTime: string;
  };
  results: T[];
};

export type SignPuddleSearchEndPointResult = {
  id: string;
  user: string;
  created_at: string;
  updated_at: string;
  sign: string;
  signtext: string;
  terms: string[];
  text: string;
  source: string;
  detail: string[];
};

export type SignPuddleSignEndPointResult = {
  id: string;
  sign: string;
};

export type PuddleInfo = {
  code: string;
  qqq: string;
  name: string;
  icon?: string;
  namespace?: string; // Optional to accommodate both uses
};

export type CountryPuddleResponse = SignPuddlePayload<{
  code: string;
  qqq: string;
  name: string;
  puddles: PuddleInfo[];
}>;

export enum SortOrder {
  Ascending = "asc",
  Descending = "desc",
}

export enum SortBy {
  Id = "id",
  User = "user",
  Sign = "sign",
  CreatedAt = "created_at",
  UpdatedAt = "updated_at",
}

export type ApiConfigurations = {
  match?: SignPuddleMatch;
  puddleCode?: string;
  countryCode?: string;
  limit?: number;
  sort?: {
    order?: "asc" | "desc";
    by?: "id" | "user" | "sign" | "created_at" | "updated_at";
  };
  caseInsensitive?: boolean;
};

export class SignPuddle3Client {
  private readonly _SIGN_PUDDLE_SERVER = "https://signpuddle.net";

  getSignsByTerm(
    term: string,
    configurations: ApiConfigurations,
  ): SignPuddlePayload<SignPuddleSearchEndPointResult> {
    const inputAsUrlParam = encodeURIComponent(term);

    const url = `${this._SIGN_PUDDLE_SERVER}/puddle/${configurations.puddleCode}/search/${inputAsUrlParam}?${this._resolveApiConfigurationsFlags(configurations)}`;
    const response:
      | SignPuddlePayload<SignPuddleSearchEndPointResult>
      | unknown = get(url);
    return response as SignPuddlePayload<SignPuddleSearchEndPointResult>;
  }

  getSignsByText(
    text: string,
    configurations: ApiConfigurations,
  ): SignPuddlePayload<SignPuddleSignEndPointResult> {
    const inputAsUrlParam = encodeURIComponent(text);

    const url = `${this._SIGN_PUDDLE_SERVER}/puddle/${configurations.puddleCode}/sign?text=${inputAsUrlParam}&${this._resolveApiConfigurationsFlags(configurations)}`;
    const response: SignPuddlePayload<SignPuddleSignEndPointResult> | unknown =
      get(url);
    return response as SignPuddlePayload<SignPuddleSignEndPointResult>;
  }

  getSignsBySource(
    source: string,
    configurations: ApiConfigurations,
  ): SignPuddlePayload<SignPuddleSignEndPointResult> {
    const inputAsUrlParam = encodeURIComponent(source);

    const url = `${this._SIGN_PUDDLE_SERVER}/puddle/${configurations.puddleCode}/sign?source=${inputAsUrlParam}&${this._resolveApiConfigurationsFlags(configurations)}`;
    const response: SignPuddlePayload<SignPuddleSignEndPointResult> | unknown =
      get(url);
    return response as SignPuddlePayload<SignPuddleSignEndPointResult>;
  }

  getSignsWithSymbols(
    symbols: string[],
    configurations: ApiConfigurations,
  ): SignPuddlePayload<SignPuddleSignEndPointResult> {
    const symbolsAsUrlParam = encodeURIComponent(symbols.join(""));
    const inputAsUrlParam = encodeURIComponent(symbolsAsUrlParam);

    const url = `${this._SIGN_PUDDLE_SERVER}/puddle/${configurations.puddleCode}/sign?query=${inputAsUrlParam}&${this._resolveApiConfigurationsFlags(configurations)}`;
    const response: SignPuddlePayload<SignPuddleSignEndPointResult> | unknown =
      get(url);
    return response as SignPuddlePayload<SignPuddleSignEndPointResult>;
  }

  getSignsById(
    ids: string[],
    configurations: ApiConfigurations,
  ): SignPuddlePayload<SignPuddleSearchEndPointResult> {
    const symbolsAsUrlParam = encodeURIComponent(ids.join(","));
    const inputAsUrlParam = encodeURIComponent(symbolsAsUrlParam);

    const url = `${this._SIGN_PUDDLE_SERVER}/puddle/${configurations.puddleCode}/entry/${inputAsUrlParam}&${this._resolveApiConfigurationsFlags(configurations)}`;
    const response:
      | SignPuddlePayload<SignPuddleSearchEndPointResult>
      | unknown = get(url);
    return response as SignPuddlePayload<SignPuddleSearchEndPointResult>;
  }

  getCountryPuddles(countryCode: string): CountryPuddleResponse {
    const url = `${this._SIGN_PUDDLE_SERVER}/world/country/${countryCode}`;
    const response: CountryPuddleResponse | unknown = get(url);
    return response as CountryPuddleResponse;
  }

  private _resolveApiConfigurationsFlags(
    configurations: ApiConfigurations,
  ): string {
    let flags = "";
    let sortOrder = ""; // Default to ascending

    if (configurations.match) {
      flags += `&match=${configurations.match}`;
    }

    if (configurations.limit) {
      flags += `&limit=${configurations.limit}`;
    }

    if (configurations.sort) {
      if (configurations.sort.order === "desc") {
        const SORT_DESCENDING = "-";
        sortOrder = SORT_DESCENDING;
      }

      flags += `&sort=${sortOrder}${configurations.sort.by}`;
    }

    if (configurations.caseInsensitive) {
      const CASE_INSENSITIVE = 1;
      flags += `&ci=${CASE_INSENSITIVE}`;
    }

    return flags;
  }
}
