<script setup lang="ts">
import { ref, watch } from "vue";
import { RouterView } from "vue-router";
import AlertComponent, {
  type AlertProps,
} from "@/components/AlertComponent/AlertComponent.vue";
import appStore from "@/stores/AppStore";
import { useTheme } from "vuetify";
import {
  addNotificationToAppAlertEventBus,
  type AppAlertEvent,
} from "./components/AlertComponent/eventBus";

const theme = useTheme();

function setVuetifyTheme(chosenTheme: "dark" | "light") {
  theme.global.name.value = chosenTheme;
}

setVuetifyTheme(appStore.state.theme.selected.value);

watch(
  () => appStore.state.theme.selected,
  (newValue) => {
    setVuetifyTheme(newValue.value);
  },
);

const infos = ref<AlertProps[]>([]);

function removeInfoById(id: string) {
  infos.value = infos.value?.filter((info) => info.id !== id);
}

function checkInfoExists(id: string) {
  return infos.value?.some((info) => info.id === id);
}

addNotificationToAppAlertEventBus({
  addNotificationToAppAlertEvent: (
    eventData: AppAlertEvent["addNotificationToAppAlertEvent"],
  ) => {
    checkInfoExists(eventData.id) || infos.value?.push(eventData);
  },
});
</script>
<template>
  <v-app>
    <v-main>
      <div class="something">
        <RouterView />
        <div class="ads">
          <!-- letrasinal-vertical-ads -->
          <ins
            class="adsbygoogle"
            style="display: block; background-color: red"
            data-ad-client="ca-pub-7505150567656139"
            data-ad-slot="8304458513"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>
      </div>
      <AlertComponent
        v-if="infos.length > 0"
        :infos="infos"
        @click:close="removeInfoById"
      />
    </v-main>
  </v-app>
</template>
<style scoped>
.something {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .ads {
    width: 20rem;
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
}

@media screen and (max-width: 768px) {
  .something {
    flex-direction: column;
    /* height: 100vh; */

    .ads {
      display: none;
      /* width: 100%;
      height: 5rem;
      border-left: none;
      border-top: 1px solid rgba(0, 0, 0, 0.12); */
    }
  }
}
</style>
