<script setup lang="ts">
import type Page from "./model";
import { pageComponentEvent } from "./eventBus";

import PageColumn from "./Column/PageColumn.vue";
import type Column from "./Column/model";

import { useI18n } from "vue-i18n";

const { t } = useI18n();

const { page } = defineProps<{ page: Page; documentTitle?: string }>();

function click() {
  pageComponentEvent.emit("update", {
    event: "click",
    pageNumber: page.order,
  });
}
</script>
<template>
  <div
    class="page"
    :style="{
      width: `${page.width}px`,
      height: `${page.height}px`,
      paddingTop: `${page.paddingTop}px`,
      paddingLeft: `${page.paddingLeft}px`,
      paddingRight: `${page.paddingRight}px`,
      paddingBottom: `${page.paddingBottom}px`,
      backgroundColor: 'white',
    }"
  >
    <div
      class="page__content"
      :style="{
        width: `${page.contentWidth}px`,
        height: `${page.contentHeight}px`,
        display: 'flex',
        flexDirection: 'row',
      }"
    >
      <PageColumn
        class="page__item"
        v-for="(column, index) in page.columns"
        :key="index"
        :column="column as Column"
      />
    </div>
    <span class="page__empty-area" @click="click"></span>
    <span class="page__footer" data-position="bottom-right">
      <div class="footer__item">
        <span class="footer__doc-title" v-show="page.footer.documentTitle">
          {{ documentTitle ?? t("document.defaultTitle") }}
        </span>
      </div>
      <div class="footer__item">
        <span class="footer__app-title" v-show="page.footer.applicationTitle">
          &copy; letrasinal.com
        </span>
      </div>
      <div class="footer__item">
        <span class="footer__page-order" v-show="page.footer.pageNumber">
          {{ page.order }}
        </span>
      </div>
    </span>
  </div>
</template>
<style scoped lang="scss">
.page {
  position: relative;
  border: 1px solid black;
  cursor: text;
  page-break-after: always;
  break-after: page;

  .page__content {
    position: relative;

    .page__item {
      z-index: 2;
    }
  }

  .page__empty-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .page__footer {
    position: absolute;
    font-size: small;
    width: 100%;
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .footer__item {
      margin: auto;
    }

    &[data-position="top-right"] {
      top: 20px;
      left: 0;
    }

    &[data-position="bottom-right"] {
      bottom: 20px;
      left: 0;
    }

    &[data-position="bottom-left"] {
      bottom: 20px;
      left: 0;
    }

    &[data-position="top-left"] {
      top: 20px;
      left: 0;
    }
  }
}

@media print {
  .page {
    border: none;
  }
}
</style>
