import { wrapEventBus } from "@/utils/eventBus";
import mitt from "mitt";

// Event type
export type documentEditorEvent = {
  [update: string]: {
    focusOn: string;
    eventValue: string;
  };
};

// Event emitter
export const documentEditorEvent = mitt<documentEditorEvent>();

// Event listener
export const documentEditorEventBus = wrapEventBus(documentEditorEvent);
