import { wrapEventBus } from "@/utils/eventBus";
import mitt from "mitt";
import type { ApiConfigurations } from "@/api/SignPuddle3Client";

// Event type
export type textInputEvent = {
  [update: string]: {
    input?: string;
    apiConfigurations: ApiConfigurations;
    focused: boolean;
  };
};

// Event emitter
export const textInputEvent = mitt<textInputEvent>();

// Event listener
export const textInputEventBus = wrapEventBus(textInputEvent);
