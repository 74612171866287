<script setup lang="ts">
import { reactive, onMounted, onUnmounted } from "vue";

import DocumentEditorModel from "./model";
import { documentEditorEvent } from "./eventBus";

import appStore from "@/stores/AppStore";

import BaseNavigationBar from "@/components/BaseNavigationBar/BaseNavigationBar.vue";

import ControlPlane from "./ControlPlane/ControlPlane.vue";
import { textInputEventBus } from "./ControlPlane/TextInput/eventBus";

import PageComponent from "./ContentManager/Paginator/Page/PageComponent.vue";
import type Page from "./ContentManager/Paginator/Page/model";
import { pageComponentEventBus } from "./ContentManager/Paginator/Page/eventBus";

import { columnItemEventBus } from "./ContentManager/Paginator/Page/Column/ColumnItem/eventBus";

import SignEditor from "./SignEditor/SignEditor.vue";

const props = defineProps<{
  model: DocumentEditorModel;
}>();

const model = reactive<DocumentEditorModel>(props.model);
model.init();

columnItemEventBus({
  columnItem: ({ id, event, at, itemDroppedId }) => {
    switch (event) {
      case "click":
        if (at) {
          at === "top"
            ? model.contentManager.clickBeforeItem(id)
            : model.contentManager.clickAfterItem(id);
        }

        model.state.global.elementOnFocus = "pages";
        model.state.items.selected = [];
        model.contentManager.caret.show();
        break;
      case "dblclick":
        break;
      case "drop": {
        if (!itemDroppedId) return;

        const position = at === "top" ? "before" : "after";

        model.contentManager.moveItem(itemDroppedId, id, position);
        model.state.global.elementOnFocus = "pages";
        break;
      }
      default:
        break;
    }
  },
});

textInputEventBus({
  update: ({ apiConfigurations, focused, input }) => {
    model.apiConfigurations.countryCode = apiConfigurations.countryCode;
    model.apiConfigurations.puddleCode = apiConfigurations.puddleCode;
    model.apiConfigurations.match = apiConfigurations.match;
    model.apiConfigurations.caseInsensitive = apiConfigurations.caseInsensitive;
    model.apiConfigurations.sort = {
      by: apiConfigurations.sort?.by,
      order: apiConfigurations.sort?.order,
    };

    if (focused) {
      model.state.global.elementOnFocus = "textInput";
      model.contentManager.caret.deactivate();
    } else {
      model.state.global.elementOnFocus = "pages";
      model.contentManager.caret.activate();
    }

    if (input) {
      model.translateInputIntoSigns(input);
    }
  },
});

pageComponentEventBus({
  update: ({ pageNumber }) => {
    model.state.global.elementOnFocus = "pages";
    model.contentManager.moveToLastOfPage(pageNumber - 1);
    model.contentManager.caret.activate();

    if (model.state.items.selected.length > 0) {
      model.state.items.selected = [];
      model.contentManager.caret.show();
    }
  },
});

async function handleKeyDown(event: KeyboardEvent) {
  if (model.state.global.elementOnFocus === "signEditor") return;

  if (model.state.global.elementOnFocus === "pages") {
    if (event.code === "Quote") {
      event.preventDefault();

      model.state.global.elementOnFocus = "textInput";

      documentEditorEvent.emit("update", {
        focusOn: model.state.global.elementOnFocus,
        eventValue: '"',
      });

      return;
    }

    if (event.key.match(/^[a-zA-Z]$/) && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();

      model.state.global.elementOnFocus = "textInput";

      documentEditorEvent.emit("update", {
        focusOn: model.state.global.elementOnFocus,
        eventValue: event.key,
      });
      return;
    }

    await model.handleKeyDown(event);
    return;
  }
}

onMounted(() =>
  document.addEventListener("keydown", (event) => handleKeyDown(event)),
);

onUnmounted(() =>
  document.removeEventListener("keydown", (event) => handleKeyDown(event)),
);
</script>
<template>
  <div
    class="document-editor__container"
    :theme="appStore.state.theme.selected.value"
  >
    <BaseNavigationBar />
    <ControlPlane :model="model as DocumentEditorModel" />
    <div class="sheets-container">
      <div
        class="sheets-container__sheets"
        :style="`zoom: ${model.state.global.zoom}%`"
      >
        <PageComponent
          v-for="(page, index) in model.contentManager.paginator.pages"
          :key="index"
          :page="page as Page"
          :documentTitle="model.metadata.title"
        />
      </div>
    </div>
    <SignEditor
      @create="model.signEditor().handleCreateEvent"
      @update="model.signEditor().handleUpdateEvent($event)"
      @close="model.signEditor().handleCloseEvent"
    />
  </div>
</template>
<style scoped lang="scss">
.document-editor__container {
  position: relative;
  background-color: rgb(128, 128, 128, 0.06);
  display: grid;
  width: 100%;
  height: 100vh;

  .sheets-container {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow: scroll;

    .sheets-container__sheets {
      height: max-content;
      width: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      margin: auto;
      padding: 0 1rem;
    }
  }

  &[theme="dark"] {
    background-color: rgb(0, 0, 0, 0.06);

    .sheets-container {
      background-color: rgb(23, 23, 23, 0.9);
    }
  }
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .document-editor__container {
    .sheets-container {
      padding: 0;
      .sheets-container__sheets {
        padding: 0;
        gap: 0;
        zoom: 100% !important;
      }
    }
  }
}
</style>
