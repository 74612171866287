<script setup lang="ts">
import SignWriting from "@/components/SignWriting/SignWriting.vue";

export type SignPunctuationProps = {
  fsw: string;
};

const props = defineProps<SignPunctuationProps>();
</script>
<template>
  <div class="sign-punctuation" text-direction="vertical">
    <SignWriting :fsw="props.fsw"></SignWriting>
  </div>
</template>
<style scoped lang="scss">
.sign-punctuation {
  &[text-direction="vertical"] {
    margin-top: 0.1rem;
    margin-bottom: 0.6rem;
    line-height: 0.5rem;
  }

  &[text-direction="horizontal"] {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    line-height: 0.5rem;
  }
}
</style>
