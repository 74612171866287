<script setup lang="ts">
import documentEditorState from "@/views/DocumentEditor/state";
import { computed } from "vue";

type SpaceComponentProps = {
  show?: boolean;
};

const props = defineProps<SpaceComponentProps>();
const show = computed(() =>
  props.show
    ? props.show
    : documentEditorState.items.showNonPrintableCharacters,
);
</script>
<template>
  <div class="space" text-direction="vertical">
    <div class="space__visual-cue" v-show="show"></div>
  </div>
</template>
<style scoped lang="scss">
.space {
  position: relative;
  width: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 1rem;

  .space__visual-cue {
    width: 0.57rem;
    height: 0.59rem;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
  }

  &[text-direction="horizontal"] {
    height: 2.5rem;
  }
}

@media print {
  .space__visual-cue {
    display: none;
  }
}
</style>
